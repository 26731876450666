import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import { Table, Divider, Breadcrumb, Button, Space, message, Badge } from "antd";
import { HomeOutlined, UserOutlined } from "@ant-design/icons";
import { db, functions } from "../../services/firebase";
import moment from "moment";
import ShopContext from "./ShopContext";

const addressFormatter = require("@fragaria/address-formatter");
var HtmlToReactParser = require("html-to-react").Parser;

function Winners() {
    const [winners, setWinners] = useState([]);
    const location = useLocation();
    const shop_id = useContext(ShopContext);
    const htmlToReactParser = new HtmlToReactParser();

    useEffect(() => {
        const unsub = db
            .collection("shops")
            .doc(shop_id)
            .collection("raffles")
            .doc(location.state.raffle_id)
            .collection("winners")
            .orderBy("size", "asc")
            .onSnapshot((snapshot) => {
                const _winners = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                console.log(_winners);
                setWinners(_winners);
            });
        return () => {
            console.log("cleanup");
            unsub();
        };
    }, []);

    function downloadPDF() {
        const formattedReleaseDate = moment(location.state.release_date.toDate()).format("DD.MM.YYYY");

        const getWinnersPDF = functions.httpsCallable("getWinnersPDF");
        getWinnersPDF({
            winners: winners,
            releaseDate: formattedReleaseDate,
            releaseTitle: location.state.release_title,
        }).then(function (result) {
            if (result.data == null) {
                message.error({ content: "Can't create pdf." });
            } else {
                console.log(result.data);

                const pdf = result.data;
                const linkSource = `data:application/pdf;base64,${pdf}`;
                const downloadLink = document.createElement("a");
                const fileName = "winners.pdf";
                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();
            }
        });
    }

    function address(data) {
        let userAddress = addressFormatter.format(
            {
                // "houseNumber": 301,
                road: data.street || null,
                // "neighbourhood": "Crescent Park",
                city: data.city || null,
                postcode: data.zipCode || null,
                // "county": "Santa Clara County",
                state: data.state || null,
                // "country": "United States of America",
                countryCode: data.country || null,
            },
            {
                appendCountry: true,
            }
        );

        userAddress = userAddress.replace(/^\s+|\s+$/g, "").replace(/\n/g, "<br />"); // remove last \n and replace others with a br

        if (data.flat != undefined && data.flat != null) {
            userAddress = data.flat + "<br />" + userAddress;
        }

        return userAddress;
    }

    const columns = [
        {
            dataIndex: "avatar",
            width: 80,
            render: (text, record) =>
                text != null ? (
                    <a href={text} target="_blank">
                        <img src={text} style={{ height: 50 }} />
                    </a>
                ) : (
                    <UserOutlined />
                ),
        },
        {
            title: "Elite",
            width: 30,
            align: "center",
            render: (t, record) => (record.is_elite == true ? <Badge color="orange" title="Elite" /> : <div />),
        },
        {
            title: "Full name",
            dataIndex: "name",
            sorter: (a, b) => {
                return a.name.localeCompare(b.name);
            },
        },
        {
            title: "Email",
            dataIndex: "email",
            sorter: (a, b) => a.email.length - b.email.length,
        },
        {
            title: "Address",
            dataIndex: "address",
            sorter: (a, b) => a.address.city.length - b.address.city.length,
            render: (txt, rec) => {
                if (rec.address != undefined) {
                    let htmlInput = address(rec.address);
                    return htmlToReactParser.parse(htmlInput);
                } else {
                    return rec.city;
                }
            },
        },
        {
            title: "Phone",
            dataIndex: "phone",
        },
        {
            title: "Size",
            width: 90,
            dataIndex: "size",
            sorter: (a, b) => a.size - b.size,
            defaultSortOrder: "ascend",
            render: (txt, rec) => {
                if (rec.size == 8000) {
                    return "-";
                } else {
                    return txt;
                }
            },
        },
    ];

    return (
        <div>
            <Breadcrumb style={{ margin: "16px 0" }}>
                <Breadcrumb.Item>
                    <HomeOutlined />
                </Breadcrumb.Item>
                <Breadcrumb.Item>Releases</Breadcrumb.Item>
                <Breadcrumb.Item>{location.state.release_title}</Breadcrumb.Item>
                <Breadcrumb.Item>Winners</Breadcrumb.Item>
            </Breadcrumb>

            <Divider />

            <Space direction="vertical" size="middle" style={{ width: "100%" }}>
                <Button type="primary" onClick={() => downloadPDF()}>
                    Download
                </Button>
                <Table dataSource={winners} columns={columns} />
            </Space>
        </div>
    );
}

export default Winners;
