import React, { useState, useEffect, useMemo } from 'react';
import { Redirect, useLocation } from "react-router-dom";
import {
  Breadcrumb,
  Form,
  Input,
  Button,
  message,
  Spin,
  Space,
  Modal
} from 'antd';
import { db, storage, dbFieldValue } from "../services/firebase";
import {
  HomeOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';
import ReactQuill, { Quill } from 'react-quill';
import ImageUploader from "quill-image-uploader";

import 'react-quill/dist/quill.snow.css';
import './PostEditor.css';

const { confirm } = Modal;

Quill.register("modules/imageUploader", ImageUploader);

function PostEditor(props) {
  const [form] = Form.useForm();
  const location = useLocation();
  const [shouldPublish, setShouldPublish] = useState(false);
  const [quillValue, setQuillValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [delta, setDelta] = useState(null);
  const [redirect, setRedirect] = useState(null);
  const [contentUpdated, setContentUpdated] = useState(false);

  let post = null;
  let postId = '';

  if (location.state.post != null) {
    post = location.state.post;
    postId = post.id;
    console.log(postId);
  } else {
    postId = location.state.postId;
    console.log(postId);
  }

  useEffect(() => {
    console.log('effect');
    if (post != null) {
      setQuillValue(post.content);
    }
  }, []);


  const onFinish = values => {
    console.log('postId', postId);
    setLoading(true);

    const doc = {
      title: values.title,
      content: quillValue
    }

    const images = delta
      .filter((op) => typeof op.insert === 'object' && op.insert.image != undefined)
      .map((op) => op.insert.image);

    if (images.length > 0) {
      doc.banner_url = images[0];
    }

    if (post == null) {
      doc.language = 'en';
      doc.published = shouldPublish;
      doc.publish_date = dbFieldValue.serverTimestamp();
      console.log('create new:', doc);

      db.collection("feed_posts").doc(postId)
        .set(doc)
        .then(function () {
          console.log("Document successfully written!");
          setRedirect('/posts');
        })
        .catch(function (error) {
          console.error("Error writing document: ", error);
        });

    } else {
      console.log('updating:', doc);

      db.collection("feed_posts").doc(postId)
        .update(doc)
        .then(function () {
          console.log("Document successfully written!");
          setRedirect('/posts');
        })
        .catch(function (error) {
          console.error("Error writing document: ", error);
        });
    } 
  }

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  const modules = useMemo(
    () => ({
      toolbar: [
        [{ 'header': [1, 2, false] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ 'align': [] }, { 'color': [] }],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
        ['link', 'image', 'video'],
        ['clean']
      ],
      imageUploader: {
        upload: file => {
          return uploadImage(file);
        }
      }
    }),
    []
  )

  // LATER: resize
  function uploadImage(file) {
    console.log('postId: ', postId);

    return new Promise((resolve, reject) => {
      console.log('Uploading: ', file);
      const type = file.type === 'image/jpeg' ? '.jpeg' : '.png';
      const storageRef = storage.ref('feed_posts').child(postId).child(uuidv4() + type);
      console.log('storageRef: ', storageRef.fullPath);

      storageRef.put(file)
        .then(function (snapshot) {
          console.log('Uploaded a blob or file!');
          snapshot.ref.getDownloadURL().then(function (downloadURL) {
            console.log('File available at', downloadURL);
            resolve(downloadURL);
          });
        })
        .catch(function (error) {
          console.error("Error uploading: ", error);
          reject('Upload failed.');
        });
    });
  }

  function showSaveAndPublishConfirm() {
    form.validateFields()
      .then(values => {
        confirm({
          title: 'Are you sure you want to publish this post?',
          icon: < ExclamationCircleOutlined />,
          content: 'All users will be notified immediately.',
          okText: 'Save & publish',
          cancelText: 'Cancel',
          onOk() {
            // message.error('Done.');
            setShouldPublish(true);
            form.submit();
          },
        });
      }).catch(errorInfo => {
        console.error("errorInfo: ", errorInfo);
      });
  }

  function onChangeEditor(content, delta, source, editor) {
    setQuillValue(content);
    setDelta(editor.getContents());
    setContentUpdated(true);
  }

  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
  };

  if (redirect != null) {
    return <Redirect to={redirect} />
  }

  return (
    <div>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>
          <HomeOutlined />
        </Breadcrumb.Item>
        <Breadcrumb.Item>Posts</Breadcrumb.Item>
        <Breadcrumb.Item>
          {post != null ? "Edit" : "Create"}
        </Breadcrumb.Item>
      </Breadcrumb>

      <Spin spinning={loading} tip="Saving...">
        <Form
          {...layout}
          form={form}
          name="basic"
          initialValues={{
            title: post && post.title
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="Title"
            name="title"
            rules={[
              {
                required: true,
                message: 'Enter the post title',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <div class="app">
            <ReactQuill
              bounds={'.app'}
              modules={modules}
              theme="snow"
              value={quillValue}
              onChange={onChangeEditor}
            />
          </div>

          <Space>
            <Button onClick={(e) => {
              form.submit();
            }} disabled={!contentUpdated}>
              {post != null ? "Save" : "Create"}
            </Button>

            {post == null &&
              <Button type="primary" onClick={showSaveAndPublishConfirm} disabled={!contentUpdated}>
                Create &amp; publish
            </Button>
            }
          </Space>

        </Form>
      </Spin>
    </div>
  );
}

export default PostEditor;