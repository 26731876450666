import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Switch } from 'react-router-dom';
import { auth } from "../../services/firebase";
import ShopReleases from './ShopReleases';
import Users from '../Users';
import ShopCreateRaffle from './ShopCreateRaffle';
import ShopContext from './ShopContext';
import Winners from './Winners';
import ShopData from './ShopData';
import Stores from './Stores';
import StoreEditor from './StoreEditor';
import ShopAccount from './ShopAccount';

// antd
import { Layout, Menu } from 'antd';
import {
  LogoutOutlined,
  AppstoreOutlined,
  UserOutlined,
  ShopOutlined,
  SettingOutlined,
  GlobalOutlined
} from '@ant-design/icons';

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

class ShopAdmin extends Component {
  constructor() {
    super();
    this.state = {
      collapsed: false,
    };
  }

  onCollapse = collapsed => {
    console.log(collapsed);
    this.setState({ collapsed });
  };

  handleRightMenuClick = e => {
    if (e.key === 'signout') {
      auth().signOut();
    }
  };

  render() {
    return (
      <ShopContext.Provider value={this.props.location.state.shop_id}>
        <Router>
          <Layout style={{ minHeight: '100vh' }}>
            <Sider collapsible collapsed={this.state.collapsed} onCollapse={this.onCollapse}>
              <img src="/images/admin_200.png" alt="Sneakerdraws" className="logo" />
              <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline">
                <Menu.Item icon={<AppstoreOutlined />}>
                  <Link to="/shop_releases">Releases</Link>
                </Menu.Item>
                <Menu.Item icon={<ShopOutlined />}>
                  <Link to="/shop">Shop</Link>
                </Menu.Item>
                <Menu.Item icon={<GlobalOutlined />}>
                  <Link to="/stores">Stores</Link>
                </Menu.Item>
                <Menu.Item icon={<SettingOutlined />}>
                  <Link to="/shop_account">Account</Link>
                </Menu.Item>
              </Menu>
            </Sider>
            <Layout className="site-layout">
              <Header className="site-layout-background" style={{ padding: 0, textAlign: 'right' }}>
                <Menu mode="horizontal" onClick={this.handleRightMenuClick} style={{ backgroundColor: '#f0f2f5' }}>
                  <SubMenu title={auth().currentUser.displayName} icon={<UserOutlined />}>
                    <Menu.Item key="signout" icon={<LogoutOutlined />}>
                      Sign out
                  </Menu.Item>
                  </SubMenu>
                </Menu>
              </Header>
              <Content style={{ margin: '0 16px' }}>
                <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                  <Switch>
                    <Route path="/shop_releases" exact>
                    {/* TODO: now it's ShopContext.Provider ?! */}
                      <ShopReleases shop_id={this.props.location.state.shop_id} /> 
                    </Route>
                    <Route path="/shop_releases/winners" exact>
                      <Winners />
                    </Route>
                    <Route path="/shop">
                      <ShopData />
                    </Route>
                    <Route path="/stores" exact>
                      <Stores />
                    </Route>
                    <Route path="/stores/store" exact>
                      <StoreEditor />
                    </Route>
                    <Route path="/shop_account">
                      <ShopAccount />
                    </Route>

                    <Route path="/shop_releases/raffle" children={<ShopCreateRaffle />} />
                  </Switch>
                </div>
              </Content>
            <Footer style={{ textAlign: 'center' }}>Sneakerdraws ©2020, v.{process.env.REACT_APP_VERSION}</Footer>
            </Layout>
          </Layout>
        </Router>
      </ShopContext.Provider>
    )
  }
}
export default ShopAdmin;
