import React, { useState, useEffect } from "react";
import {
    Table,
    Button,
    Divider,
    Breadcrumb,
    Space,
    Modal,
    Spin,
    notification,
    message,
    Tooltip,
    Badge,
    InputNumber,
} from "antd";
import { HomeOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Link, useHistory } from "react-router-dom";
import { db, functions } from "../services/firebase";
import moment from "moment";
import firebase from "firebase";
const { confirm } = Modal;

function shuffle(a) {
    for (let i = a.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
}

function Releases(props) {
    let history = useHistory();
    const [releases, setReleases] = useState([]);
    const [creatingRaffle, setCreatingRaffle] = useState(false);
    var eliteUsersPercentage = 80;

    useEffect(() => {
        const unsub = db
            .collection("releases")
            .orderBy("release_date", "desc")
            .onSnapshot((snapshot) => {
                const allReleases = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    rel_date: moment(doc.get("release_date").toDate()).format("DD.MM.YYYY, HH:mm"),
                    price_formatted: "€ " + doc.get("price"),
                    ...doc.data(),
                }));
                setReleases(allReleases);
            });
        return () => {
            console.log("cleanup");
            unsub();
        };
    }, []);

    function editRelease(record_id) {
        const release = releases.find((release) => release.id === record_id);
        console.log(release);
        history.push({
            pathname: "/release",
            state: { release: release },
        });
    }

    function showDeleteConfirm(documentId, releaseTitle) {
        confirm({
            title: "Are you sure you want to delete this release?",
            icon: <ExclamationCircleOutlined />,
            content: releaseTitle + " and all its raffles will be gone forever.",
            okText: "Delete",
            okType: "danger",
            cancelText: "Cancel",
            onOk() {
                message.error("Not supported yet.");
            },
        });
    }

    function raffleConfirmContent() {
        return (
            <>
                Elite winners:&nbsp;
                <InputNumber
                    defaultValue={eliteUsersPercentage}
                    min={0}
                    max={100}
                    formatter={(value) => `${value}%`}
                    parser={(value) => value.replace("%", "")}
                    onChange={(value) => (eliteUsersPercentage = value)}
                />
            </>
        );
    }

    function showRaffleConfirm(documentId, releaseTitle, firstImageUrl, is_elite) {
        confirm({
            title: "Are you sure you want to raffle '" + releaseTitle + "'?",
            icon: <ExclamationCircleOutlined />,
            content: raffleConfirmContent(),
            okText: "Raffle",
            cancelText: "Cancel",
            onOk() {
                raffle(documentId, firstImageUrl, is_elite);
            },
        });
    }

    function raffle(documentId, firstImageUrl, is_elite) {
        console.log(documentId);
        setCreatingRaffle(true);

        const releaseRef = db.collection("releases").doc(documentId);

        // 1. Get 2 matrixes
        Promise.all([getShopSizesMatrix(releaseRef), getUserMatrix(releaseRef, is_elite)]).then(function (result) {
            const shopSizes = result[0];
            const users = result[1]; // Users is an already shuffled array

            const reducer = (accumulator, currentValue) => accumulator + currentValue.pairs;
            const sum_pairs = shopSizes.reduce(reducer, 0);
            console.log("sum_pairs: ", sum_pairs);

            const max_elite_users_perc = eliteUsersPercentage / 100;
            console.log("max_elite_users (%): ", max_elite_users_perc);

            var max_elite_users = Math.round(max_elite_users_perc * sum_pairs);
            console.log("max_elite_users: ", max_elite_users);

            // 2.Iterate over shopSizes
            shopSizes.forEach((shopSize) => {
                const pairsAvailable = shopSize.pairs;

                for (let idx = 0; idx < pairsAvailable; idx++) {
                    const isWinner = (user) =>
                        user.size === shopSize.size &&
                        user.shops.includes(shopSize.shop) &&
                        user.is_banned == false &&
                        user.w_shop == null;

                    const isEliteWinner = (user) =>
                        user.size === shopSize.size &&
                        user.shops.includes(shopSize.shop) &&
                        user.is_banned == false &&
                        user.w_shop == null &&
                        user.is_elite == true;

                    var winnerIdx = -1;
                    if (is_elite != true && max_elite_users > 0) {
                        winnerIdx = users.findIndex(isEliteWinner);
                        max_elite_users--;
                        console.log("winner id (ELITE): ", winnerIdx);
                        console.log("max_elite_users left: ", max_elite_users);
                    }

                    if (winnerIdx == -1) {
                        winnerIdx = users.findIndex(isWinner);
                        console.log("winner id: ", winnerIdx);
                    }

                    if (winnerIdx > -1) {
                        users[winnerIdx].w_shop = shopSize.shop;
                        users[winnerIdx].w_pickUpLocation = shopSize.pickUpLocation;
                        users[winnerIdx].w_shipping = shopSize.shipping;
                        users[winnerIdx].raffleId = shopSize.raffleId;
                    } else {
                        console.warn("winner not found");
                    }
                }
            });

            const winners = users.filter((user) => user.w_shop != null);
            const losers = users.filter((user) => user.w_shop == null);

            console.log(`Updating winners & losers...`);

            return updateLosers(losers, firstImageUrl).then(function () {
                updateWinners(releaseRef, winners, firstImageUrl);
            });
        });
    }

    // Returned matrix: shop | size | pairs
    function getShopSizesMatrix(releaseRef) {
        return new Promise((resolve, reject) => {
            db.collectionGroup("raffles")
                .where("release_ref", "==", releaseRef)
                .get()
                .then(function (querySnapshot) {
                    const shopSizes = [];
                    querySnapshot.forEach((shopSizeDoc) => {
                        for (let [size, pairs] of Object.entries(shopSizeDoc.get("sizes"))) {
                            shopSizes.push({
                                raffleId: shopSizeDoc.ref,
                                shop: shopSizeDoc.ref.parent.parent.id,
                                size: parseFloat(size),
                                pairs: pairs,
                                pickUpLocation: shopSizeDoc.get("pick_up_location") || null,
                                shipping: shopSizeDoc.get("shipping") || null,
                            });
                        }
                    });
                    resolve(shuffle(shopSizes));
                })
                .catch(function (error) {
                    console.error("Error getting getShopSizesMatrix: ", error);
                    reject(error);
                });
        });
    }

    // Returned matrix: user | userRaffleRef | size | shops (array)
    function getUserMatrix(releaseRef, elite_only) {
        return new Promise((resolve, reject) => {
            var ref = db.collectionGroup("user_raffles").where("release_ref", "==", releaseRef);
            if (elite_only == true) {
                ref = ref.where("is_elite", "==", true);
            }

            ref.get()
                .then(function (querySnapshot) {
                    var users = [];
                    querySnapshot.forEach((userRaffle) => {
                        const user = userRaffle.ref.parent.parent.id;
                        const userName = userRaffle.get("user_name");
                        const userEmail = userRaffle.get("user_email");
                        const userAvatar = userRaffle.get("user_avatar");
                        const is_banned = userRaffle.get("is_banned");
                        const is_elite = userRaffle.get("is_elite");
                        const phone = userRaffle.get("phone");
                        const address = userRaffle.get("address");
                        const use_shipping = userRaffle.get("use_shipping");
                        const userRaffleRef = userRaffle.ref;
                        const size = userRaffle.get("size");
                        const shops = userRaffle.get("entered_shop_refs").map((locRef) => locRef.id);
                        const element = {
                            user: user,
                            userName: userName,
                            userEmail: userEmail,
                            userRaffleRef: userRaffleRef,
                            size: size,
                            shops: shops,
                        };

                        element.userAvatar = userAvatar != undefined ? userAvatar : null;
                        element.is_banned = is_banned != undefined ? is_banned : false;
                        element.is_elite = is_elite != undefined ? is_elite : false;
                        element.phone = phone != undefined ? phone : null;
                        element.address = address != undefined ? address : null;
                        element.use_shipping = use_shipping != undefined ? use_shipping : false;
                        console.log("user element: ", element);
                        users.push(element);
                    });
                    resolve(shuffle(users));
                })
                .catch(function (error) {
                    console.error("Error getting getUserMatrix: ", error);
                    reject(error);
                });
        });
    }

    // Batch writes to db for release (mark as done), winners & shops
    function updateWinners(releaseRef, winners, firstImageUrl) {
        if (winners.length === 0) {
            notification["warning"]({
                message: "No winners",
                description: "There is not a single winner in this raffle 😕",
            });
            setCreatingRaffle(false);
            return;
        } else if (winners.length > 500 / 2 - 1) {
            // Also consider updates for shops and release as separate updates
            notification["error"]({
                message: "Technical limit reached",
                description: "Can't process more than 500 winners. Contact the dev...",
            });
            setCreatingRaffle(false);
            return;
        }

        console.log("Writing winners for releaseId: ", releaseRef.id);
        console.log(winners);

        var batch = db.batch();

        winners.forEach((winner) => {
            let winnerData = {
                did_win: true,
                did_show_win_popup_in_app: false,
                image_url: firstImageUrl,
            };
            if (winner.use_shipping == true) {
                winnerData["won_shipping"] = winner.w_shipping;
            } else {
                winnerData["won_instore"] = winner.w_pickUpLocation;
            }
            batch.update(winner.userRaffleRef, winnerData);

            batch.set(winner.raffleId.collection("winners").doc(), {
                name: winner.userName,
                size: winner.size,
                avatar: winner.userAvatar,
                email: winner.userEmail,
                phone: winner.phone,
                address: winner.address,
                use_shipping: winner.use_shipping,
                is_elite: winner.is_elite,
            });
        });

        batch.update(releaseRef, {
            raffle_did_run: true,
            raffle_ran_on: firebase.firestore.Timestamp.fromDate(new Date()),
        });

        // LATER: if more than 500, then separate winner & shop updates

        return batch
            .commit()
            .then(function () {
                // Call firebase func to trigger shop notifications for this `releaseRef`
                console.log(`Call firebase func to trigger shop notifications for ${releaseRef.id}`);

                var notifyShopsAboutWinners = functions.httpsCallable("notifyShopsAboutWinners");
                return notifyShopsAboutWinners({ releaseId: releaseRef.id });
            })
            .then(function () {
                setCreatingRaffle(false);

                notification["success"]({
                    message: "Done",
                    description: "Raffle completed. Winners and shops were notified.",
                });

                console.log("Raffle complete. Shops notified.");
            })
            .catch(function (error) {
                // var code = error.code;
                // var message = error.message;
                // var details = error.details;
                console.log("error! ");
                console.log(error);
            });
    }

    // Set `did_win = false` so that the loosers immidiately see if they've lost
    function updateLosers(losers, firstImageUrl) {
        console.log("Updating losers in batches:", losers.length);

        const promises = [];
        var i,
            j,
            chunk = 500;
        for (i = 0, j = losers.length; i < j; i += chunk) {
            const chunkOfLosers = losers.slice(i, i + chunk);
            console.log("added to losers chunk: ", chunkOfLosers.length);
            promises.push(updateChunkOfLosers(chunkOfLosers, firstImageUrl));
        }
        return Promise.all(promises);
    }

    function updateChunkOfLosers(losers, firstImageUrl) {
        console.log("Starting batch of losers:", losers.length);
        var batch = db.batch();
        losers.forEach((loser) => {
            batch.update(loser.userRaffleRef, {
                did_win: false,
                did_show_win_popup_in_app: false,
                image_url: firstImageUrl,
            });
        });
        return batch.commit();
    }

    function renderLocation(location) {
        switch (location) {
            case "Europe":
                return "🇪🇺";
            case "US":
                return "🇺🇸";
            case "World":
                return "🌍";
            default:
                return "🏳️‍🌈";
        }
    }

    const columns = [
        {
            title: "Elite",
            width: 30,
            align: "center",
            render: (t, record) => (record.is_elite == true ? <Badge color="orange" title="Elite" /> : <div />),
        },
        {
            title: "Title",
            dataIndex: "title",
        },
        {
            title: "Price",
            width: 140,
            dataIndex: "price_formatted",
        },
        {
            title: "",
            width: 50,
            dataIndex: "location",
            render: (t, record) => renderLocation(record.location),
        },
        {
            title: "Release Date",
            dataIndex: "rel_date",
            width: 180,
            sorter: (a, b) => a.release_date - b.release_date,
        },
        {
            title: "Action",
            dataIndex: "action",
            width: 200,
            align: "right",
            render: (text, record) => (
                <Space size="middle">
                    <Tooltip
                        title={
                            record.raffle_ran_on != null
                                ? "This raffle ran on " +
                                  moment(record.raffle_ran_on.toDate()).format("DD.MM.YYYY, HH:mm")
                                : ""
                        }
                    >
                        <Button
                            disabled={record.raffle_did_run}
                            onClick={() =>
                                showRaffleConfirm(record.id, record.title, record.images[0], record.is_elite || false)
                            }
                        >
                            Raffle
                        </Button>
                    </Tooltip>

                    <Button danger onClick={() => showDeleteConfirm(record.id, record.title)}>
                        Delete
                    </Button>

                    <Button onClick={() => editRelease(record.id)}>Edit</Button>
                </Space>
            ),
        },
    ];

    return (
        <div>
            <Breadcrumb style={{ margin: "16px 0" }}>
                <Breadcrumb.Item>
                    <HomeOutlined />
                </Breadcrumb.Item>
                <Breadcrumb.Item>Releases </Breadcrumb.Item> <Breadcrumb.Item>Overview</Breadcrumb.Item>
            </Breadcrumb>
            <Link to="/release">
                <Button type="primary">New Release</Button>
            </Link>
            <Divider />
            <Spin spinning={creatingRaffle} tip="Creating a raffle, please wait...">
                <Table dataSource={releases} columns={columns} />{" "}
            </Spin>{" "}
        </div>
    );
}

export default Releases;
