import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { message } from "antd";
// Services
import { auth, functions } from "./services/firebase";

// Pages
import Login from "./pages/Login";
import Admin from "./pages/Admin";
import ShopAdmin from "./pages/Shop Admin/ShopAdmin";

import "./App.css";

function PrivateRoute({ component: Component, authenticated, role, ...rest }) {
    return (
        <Route
            {...rest}
            render={(props) =>
                authenticated === true ? (
                    <Component {...props} />
                ) : (
                    <Redirect to={{ pathname: "/", state: { from: props.location } }} />
                )
            }
        />
    );
}

function PublicRoute({ component: Component, authenticated, role, shop_id, ...rest }) {
    return (
        <Route
            {...rest}
            render={(props) =>
                authenticated === false ? (
                    <Component {...props} />
                ) : role === "admin" ? (
                    <Redirect to="/releases" /> //
                ) : role === "shop_admin" ? (
                    <Redirect
                        to={{
                            pathname: "/shop_releases",
                            state: { shop_id: shop_id },
                        }}
                    />
                ) : (
                    <Component {...props} />
                )
            }
        />
    );
}

class App extends Component {
    constructor() {
        super();
        this.state = {
            authenticated: false,
            role: "", // admin || shop_admin
            shop_id: "", // in case of shop_admin
            loading: true,
        };
    }

    componentDidMount() {
        auth().onAuthStateChanged((user) => {
            if (user) {
                // console.log(user);
                const getUserRole = functions.httpsCallable("getUserRole");
                getUserRole({ uid: user.uid }).then((result) => {
                    // console.log(result);
                    if (result.data.role === "admin" || result.data.role === "shop_admin") {
                        this.setState({
                            authenticated: true,
                            role: result.data.role,
                            shop_id: result.data.shop_id,
                            loading: false,
                        });
                    } else {
                        this.setState({
                            authenticated: false,
                            role: "",
                            shop_id: "",
                            loading: false,
                        });
                        message.error("Wrong user role");
                    }
                });
            } else {
                this.setState({
                    authenticated: false,
                    role: "",
                    shop_id: "",
                    loading: false,
                });
            }
        });
    }

    render() {
        return (
            <Router>
                <Switch>
                    {/* <Route path="/" exact component={Login} /> */}

                    {/* <PrivateRoute
              path="/admin"
              authenticated={this.state.authenticated}
              role={this.state.role}
              component={Admin}
            /> */}

                    <PrivateRoute
                        path="/releases"
                        authenticated={this.state.authenticated}
                        role={this.state.role}
                        component={Admin}
                    />

                    <PrivateRoute
                        path="/shop_releases"
                        authenticated={this.state.authenticated}
                        role={this.state.role}
                        component={ShopAdmin}
                    />

                    <PublicRoute
                        path="/"
                        authenticated={this.state.authenticated}
                        role={this.state.role}
                        shop_id={this.state.shop_id}
                        component={Login}
                    />
                </Switch>
            </Router>
        );
    }
}

export default App;
