import React, { useState } from "react";
import { Redirect, useLocation } from "react-router-dom";
import {
    InputNumber,
    Upload,
    Breadcrumb,
    Form,
    Input,
    Button,
    message,
    Spin,
    Select,
    DatePicker,
    Divider,
    Checkbox,
} from "antd";
import { db, storage } from "../services/firebase";
import moment from "moment";
import ReleaseEditorImages from "./ReleaseEditorImages";
import { HomeOutlined, UploadOutlined } from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid";

const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 8 },
};

const tailLayout = {
    wrapperCol: { offset: 4, span: 2 },
};

const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
};

const { TextArea } = Input;
const { Option } = Select;

function ReleaseEditor(props) {
    const [fileList, setFileList] = useState([]);
    const [redirect, setRedirect] = useState(null);
    const [loading, setLoading] = useState(false);
    const location = useLocation();

    let release = null;
    if (location.state != null) {
        release = location.state.release;
        console.log(release);
        // form.setFieldsValue({ region: release.region });
    }

    const onFinish = (values) => {
        console.log("Saving:", values);
        message.loading({ content: "Saving...", key: 1, duration: 60 });
        setLoading(true);

        const doc = {
            title: values.title,
            description: values.description,
            price: values.price,
            release_date: values.release_date.toDate(),
            one_size: values.one_size,
            is_elite: values.is_elite,
            location: values.region,
        };

        if (release == null) {
            doc.has_raffles = false;
            doc.raffle_did_run = false;
            doc.published = true;

            const releaseRef = db.collection("releases").doc();
            const imageUploads = [];
            fileList.forEach((file) => {
                imageUploads.push(uploadImage(releaseRef.id, file));
            });

            console.log("imageUploads:", imageUploads);

            Promise.all(imageUploads)
                .then((image_urls) => {
                    doc.images = image_urls;
                    console.log("Creating:", doc);
                    return releaseRef.set(doc);
                })
                .then(function () {
                    message.success({ content: "Success", key: 1, duration: 2 });
                    setRedirect("/releases");
                })
                .catch(function (error) {
                    console.error("Error adding document: ", error);
                });
        } else {
            console.log("Updating:", doc);
            const releaseRef = db.collection("releases").doc(release.id);
            releaseRef
                .update(doc)
                .then(function () {
                    message.success({ content: "Success", key: 1, duration: 2 });
                    setRedirect("/releases");
                })
                .catch(function (error) {
                    console.error("Error adding document: ", error);
                });
        }
    };

    function uploadImage(releaseId, file) {
        console.log("releaseId: ", releaseId);

        return new Promise((resolve, reject) => {
            console.log("Uploading: ", file);
            const type = file.type === "image/jpeg" ? ".jpeg" : ".png";
            const storageRef = storage
                .ref("releases")
                .child(releaseId)
                .child(uuidv4() + type);
            console.log("storageRef: ", storageRef.fullPath);

            storageRef
                .put(file)
                .then(function (snapshot) {
                    console.log("Uploaded a blob or file!");
                    snapshot.ref.getDownloadURL().then(function (downloadURL) {
                        console.log("File available at", downloadURL);
                        resolve(downloadURL);
                    });
                })
                .catch(function (error) {
                    console.error("Error uploading: ", error);
                    reject("nope");
                });
        });
    }

    const upload_props = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
            console.log("after remove: ", fileList);
        },
        beforeUpload: (file) => {
            console.log("adding file: ", file);
            if (file.size >= 5 * 1024 * 1024) {
                console.log("Too big: ", file.size);
                message.error("File size is too large. Must be smaller than 5MB.");
                return false;
            }
            fileList.push(file);
            // setFileList(newFileList);
            console.log("beforeUpload: ", fileList);
            return true;
        },
        fileList,
    };

    const normFile = (e) => {
        console.log("Upload event:", e);
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    if (redirect != null) {
        return <Redirect to={redirect} />;
    }

    function disabledDate(current) {
        // Can not select days before today.
        return current && current < moment().subtract(1, "days").endOf("day");
    }

    return (
        <div>
            <Breadcrumb style={{ margin: "16px 0" }}>
                <Breadcrumb.Item>
                    <HomeOutlined />
                </Breadcrumb.Item>
                <Breadcrumb.Item>Releases</Breadcrumb.Item>
                <Breadcrumb.Item>{release != null ? "Edit" : "Create"}</Breadcrumb.Item>
            </Breadcrumb>

            <Spin spinning={loading} tip="Uploading...">
                <Form
                    {...layout}
                    name="basic"
                    initialValues={{
                        title: release && release.title,
                        description: release && release.description,
                        price: release && release.price,
                        release_date: release && moment(release.release_date.toDate()),
                        one_size: release && release.one_size,
                        is_elite: release && release.is_elite,
                        region: release && release.location,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >
                    <Form.Item
                        label="Release title"
                        name="title"
                        rules={[
                            {
                                required: true,
                                message: "Enter the release title",
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item label="Description" name="description">
                        <TextArea rows={8} />
                    </Form.Item>

                    <Form.Item label="Elite" name="is_elite" valuePropName="checked">
                        <Checkbox>For elite members only</Checkbox>
                    </Form.Item>

                    <Form.Item label="Release type" name="one_size" valuePropName="checked">
                        <Checkbox>One size</Checkbox>
                    </Form.Item>

                    <Form.Item label="Region" name="region">
                        <Select defaultValue="Europe" style={{ width: 180 }}>
                            <Option value="Europe">Europe</Option>
                            <Option value="US">U.S.</Option>
                            <Option value="World">Worldwide</Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="Price"
                        name="price"
                        rules={[
                            {
                                required: true,
                                message: "Enter the price",
                            },
                        ]}
                    >
                        <InputNumber
                            defaultValue={100}
                            style={{ width: 180 }}
                            min={0}
                            formatter={(value) => `€ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            parser={(value) => value.replace(/€\s?|(,*)/g, "")}
                        />
                    </Form.Item>

                    <Form.Item
                        label="Release date"
                        name="release_date"
                        rules={[
                            {
                                required: true,
                                message: "Enter the release date",
                            },
                            ({ getFieldValue }) => ({
                                validator(rule, value) {
                                    if (value >= moment()) {
                                        return Promise.resolve();
                                    }

                                    return Promise.reject("Release date should be in the future.");
                                },
                            }),
                        ]}
                    >
                        <DatePicker
                            style={{ width: 180 }}
                            showTime
                            format="DD.MM.YYYY HH:mm"
                            disabledDate={disabledDate}
                            minuteStep={15}
                        />
                    </Form.Item>

                    {/* Visible only when creating new release. */}
                    {release == null ? (
                        <Form.Item
                            name="upload"
                            label="Images"
                            valuePropName="fileList"
                            getValueFromEvent={normFile}
                            rules={[
                                {
                                    required: true,
                                    message: "At least 1 image is required",
                                },
                            ]}
                        >
                            <Upload listType="picture" {...upload_props}>
                                <Button>
                                    <UploadOutlined /> Select images
                                </Button>
                            </Upload>
                        </Form.Item>
                    ) : (
                        <></>
                    )}

                    <Form.Item {...tailLayout}>
                        <Button type="primary" htmlType="submit">
                            Save
                        </Button>
                    </Form.Item>
                </Form>
            </Spin>

            {
                // when editing: show a table with images. actions: move up/down, delete
                // button to upload new image(s)
                release && (
                    <>
                        <Divider />
                        <ReleaseEditorImages release_id={release.id} />
                    </>
                )
            }
        </div>
    );
}

export default ReleaseEditor;
