import React, { useState, useEffect, useContext } from "react";
import { useLocation, useHistory } from "react-router-dom";
import {
    Row,
    Col,
    Breadcrumb,
    Divider,
    Form,
    Button,
    InputNumber,
    Select,
    DatePicker,
    Spin,
    message,
    Radio,
    Input,
} from "antd";
import { HomeOutlined } from "@ant-design/icons";
import moment from "moment";
import ShopRaffleInfo from "./ShopRaffleInfo";
import { db, dbFieldValue } from "../../services/firebase";
import "./ShopCreateRaffle.css";
import ShopContext from "./ShopContext";

const { Option } = Select;

function ShopCreateRaffle() {
    const history = useHistory();
    const location = useLocation();
    const shop_id = useContext(ShopContext);
    const record_id = location.state.record_id;
    const release_date = location.state.release_date;
    const release_title = location.state.release_title;
    const one_size = location.state.one_size;

    const [form] = Form.useForm();

    const [sizes, setSizes] = useState([]);
    const [locations, setLocations] = useState([]);
    const [saving, setSaving] = useState(false);
    const [shippingOption, setShippingOption] = useState("shipping");

    function disabledDate(current) {
        return (
            current &&
            (current < moment().subtract(1, "days").endOf("day") ||
                current > moment(release_date.toDate()).subtract(14, "minutes"))
        );
    }

    useEffect(() => {
        setSizes([
            36,
            36.5,
            37.5,
            38,
            38.5,
            39,
            40,
            40.5,
            41,
            42,
            42.5,
            43,
            44,
            44.5,
            45,
            45.5,
            46,
            46.5,
            47,
            47.5,
            48,
            48.5,
            49.5,
        ]);

        if (location.state.edit_raffle != undefined) {
            console.log("SET SHOP:", location.state.edit_raffle.shippingOption);
            form.setFieldsValue({ shipping: location.state.edit_raffle.shippingOption });
        }

        const unsub = db
            .collection("shops")
            .doc(shop_id)
            .collection("locations")
            .orderBy("street", "asc")
            .onSnapshot((snapshot) => {
                const allLocations = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                console.log(allLocations);
                setLocations(allLocations);
            });
        return () => {
            console.log("cleanup");
            unsub();
        };
    }, []);

    function getFormSizes(form_values) {
        let _sizes = {};
        for (let [key, value] of Object.entries(form_values)) {
            if (value > 0 && key.startsWith("size.")) {
                _sizes[key.substring(5)] = value;
            }
        }

        return _sizes;
    }

    async function locationData(pick_up_location_id) {
        const locationRef = db.collection("shops").doc(shop_id).collection("locations").doc(pick_up_location_id);
        const doc = await locationRef.get();
        if (doc.exists) {
            console.log("Document data:", doc.data());
            return doc.data();
        } else {
            console.log("No such document!");
        }
    }

    async function shopData() {
        const locationRef = db.collection("shops").doc(shop_id);
        return await locationRef
            .get()
            .then(function (doc) {
                if (doc.exists) {
                    console.log("Document data:", doc.data());
                    return doc.data();
                } else {
                    console.log("No such document!");
                }
            })
            .catch(function (error) {
                console.log("Error getting document:", error);
            });
    }

    const onFinish = (values) => {
        console.log("Received values of form:", values);
        sendForm(values);
    };

    async function sendForm(values) {
        setSaving(true);

        const _sizes = getFormSizes(values);
        if (Object.entries(_sizes).length <= 0) {
            message.warning("Please enter at least one size.");
            setSaving(false);
            return;
        }

        let doc = {
            entry_allowed_till: values.closing_date.toDate(),
            sizes: _sizes,
        };

        let loc = null;
        if (values.pick_up_location != null) {
            loc = await locationData(values.pick_up_location);

            loc["location_ref"] = db
                .collection("shops")
                .doc(shop_id)
                .collection("locations")
                .doc(values.pick_up_location);
        }

        const shippingData = { price: 20, region: "Europe" };
        switch (values.shipping) {
            case "shipping":
                doc["shipping"] = shippingData;

                if (location.state.edit_raffle != null) {
                    doc["pick_up_location"] = dbFieldValue.delete();
                }
                break;
            case "instore":
                if (loc != null) {
                    doc["pick_up_location"] = loc;
                }
                if (location.state.edit_raffle != null) {
                    doc["shipping"] = dbFieldValue.delete();
                }
                break;
            case "combined":
                doc["shipping"] = shippingData;
                if (loc != null) {
                    doc["pick_up_location"] = loc;
                }
                break;
            default:
                console.log("Unknown shipping method: ", values.shipping);
        }

        if (location.state.edit_raffle == null) {
            doc["release_ref"] = db.collection("releases").doc(record_id);
            doc["release_title"] = release_title;
            doc["release_date"] = release_date;

            shopData()
                .then((shop) => {
                    console.log("shop:", shop);
                    doc["shop_email"] = shop["email"];
                    doc["shop_hq"] = shop["hq"];

                    console.log("Creating new raffle: ", doc);

                    const raffleRef = db.collection("shops").doc(shop_id).collection("raffles").doc();
                    return raffleRef.set(doc);
                })
                .then((e) => {
                    openReleases();
                });
        } else {
            const raffleRef = db
                .collection("shops")
                .doc(shop_id)
                .collection("raffles")
                .doc(location.state.edit_raffle.raffle_id);
            // Don't edit pick up location & shipping:
            delete doc["pick_up_location"];
            delete doc["shipping"];

            console.log("Updating raffle: ", raffleRef.path, "Doc:", doc);
            raffleRef.update(doc).then((e) => {
                openReleases();
            });
        }
    }

    function openReleases() {
        history.push({
            pathname: "/shop_releases",
        });
    }

    // Takes {42.2: 10, 43: 3} and maps it into form's {size.42.2: 10, size.43: 3}
    function formEncodedSizes() {
        if (location.state.edit_raffle != null) {
            let _sizes = {};
            for (let [key, value] of Object.entries(location.state.edit_raffle.sizes)) {
                _sizes["size." + key] = value;
            }
            console.log("_sizes: ", _sizes);
            return _sizes;
        }
        return {};
    }

    const radioStyle = {
        display: "block",
        height: "30px",
        lineHeight: "30px",
    };

    const onChangeShipping = (e) => {
        console.log("radio checked", e.target.value);
        setShippingOption(e.target.value);
    };

    return (
        <div>
            <Breadcrumb style={{ margin: "16px 0" }}>
                <Breadcrumb.Item>
                    <HomeOutlined />
                </Breadcrumb.Item>
                <Breadcrumb.Item>Releases</Breadcrumb.Item>
                <Breadcrumb.Item>
                    {location.state.edit_raffle == null ? "Create raffle" : "Edit raffle"}
                </Breadcrumb.Item>
            </Breadcrumb>

            <Spin spinning={saving} tip="Saving...">
                <Row>
                    <Col span={14}>
                        <Form
                            form={form}
                            name="dynamic_form_item"
                            layout="horizontal"
                            size="middle"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 14 }}
                            onFinish={onFinish}
                            initialValues={{
                                closing_date: location.state.edit_raffle
                                    ? moment(location.state.edit_raffle.entry_allowed_till.toDate())
                                    : moment(release_date.toDate()).startOf("day"),
                                pick_up_location: location.state.edit_raffle
                                    ? location.state.edit_raffle.pick_up_location_id
                                    : null,
                                shipping: shippingOption,
                                ...formEncodedSizes(),
                            }}
                        >
                            <Divider>Raffle options</Divider>

                            <Form.Item name="shipping" label="Shipping">
                                <Radio.Group
                                    onChange={onChangeShipping}
                                    value={shippingOption}
                                    disabled={location.state.edit_raffle != undefined}
                                >
                                    <Radio style={radioStyle} value="shipping">
                                        Shipping (Europe, 20€)
                                    </Radio>
                                    <Radio style={radioStyle} value="instore">
                                        In-Store
                                    </Radio>
                                    <Radio style={radioStyle} value="combined">
                                        Shipping + In-Store
                                    </Radio>
                                </Radio.Group>
                            </Form.Item>

                            <Form.Item
                                shouldUpdate
                                name="pick_up_location"
                                label="Pick-up location"
                                rules={[
                                    {
                                        required: shippingOption != "shipping",
                                        message: "Please select pick-up location",
                                    },
                                ]}
                                hidden={shippingOption == "shipping"}
                            >
                                <Select
                                    defaultValue=""
                                    style={{ width: "80%" }}
                                    disabled={location.state.edit_raffle != undefined}
                                >
                                    {locations.map((item) => (
                                        <Option value={item.id}>
                                            {item.street}, {item.city}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>

                            <Form.Item
                                name="closing_date"
                                label="Closing date & time"
                                rules={[{ required: true, message: "Please enter closing date & time" }]}
                            >
                                <DatePicker
                                    showTime={{ format: "HH:mm" }}
                                    minuteStep={15}
                                    showToday={false}
                                    format="DD.MM.YYYY HH:mm"
                                    disabledDate={disabledDate}
                                    allowClear={false}
                                />
                            </Form.Item>
                            {one_size == true ? (
                                <div>
                                    <Form.Item name="size.8000" label="One size">
                                        <InputNumber min={0} precision={0} defaultValue={0} placeholder="Items" />
                                    </Form.Item>
                                </div>
                            ) : (
                                <div>
                                    <Divider>Available sizes</Divider>
                                    {sizes.map((size) => (
                                        <Form.Item name={"size." + size.toString()} label={size.toString()}>
                                            <InputNumber min={0} precision={0} defaultValue={0} placeholder="Pairs" />
                                        </Form.Item>
                                    ))}
                                </div>
                            )}
                            <Form.Item> </Form.Item>
                            <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
                                <Button htmlType="submit" type="primary" size="middle">
                                    Save
                                </Button>
                            </Form.Item>
                        </Form>
                    </Col>
                    <Col span={1}></Col>
                    <Col span={9}>
                        <ShopRaffleInfo record_id={record_id} />
                    </Col>
                </Row>
            </Spin>
        </div>
    );
}

export default ShopCreateRaffle;
