import React, { useState, useEffect, useContext } from "react";
import { Table, Button, Divider, Breadcrumb, Space, Modal, message, Badge, Tooltip } from "antd";
import { HomeOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Link, useHistory } from "react-router-dom";
import { db } from "../../services/firebase";
import moment from "moment";
import ShopContext from "./ShopContext";
import Winners from "./Winners";
const { confirm } = Modal;

function ShopReleases(props) {
    let history = useHistory();
    const [releases, setReleases] = useState([]);
    const shop_id = useContext(ShopContext);

    useEffect(() => {
        const releaseRef = db.collection("releases").doc("rO3Je873ciBA7rShaD7y");
        var raffles = db
            .collectionGroup("user_raffles")
            .where("did_win", "==", true)
            .where("release_ref", "==", releaseRef);
        raffles.get().then(function (querySnapshot) {
            console.log("done. ");
            console.log(querySnapshot.size);
            querySnapshot.forEach(function (doc) {
                if (doc.get("won_instore") == undefined && doc.get("won_shipping") == undefined) {
                    console.log("=============");
                    console.log(doc.get("user_name"));
                    console.log(doc.get("user_email"));
                    console.log(doc.get("size"));
                }
            });
        });

        const promises = [];
        const promiseReleases = new Promise(async (resolve, reject) => {
            db.collection("releases")
                .where("published", "==", true)
                .orderBy("release_date", "desc")
                .onSnapshot((snapshot) => {
                    const allReleases = snapshot.docs.map((doc) => ({
                        id: doc.id,
                        rel_date: moment(doc.get("release_date").toDate()).format("DD.MM.YYYY, HH:mm"),
                        price_formatted: "€ " + doc.get("price"),
                        ...doc.data(),
                    }));
                    resolve({ allReleases: allReleases });
                });
        });
        promises.push(promiseReleases);

        const promiseRaffles = new Promise(async (resolve, reject) => {
            db.collection("shops")
                .doc(shop_id)
                .collection("raffles")
                .onSnapshot((snapshot) => {
                    const myRaffles = snapshot.docs.map((doc) => ({
                        id: doc.id,
                        ...doc.data(),
                    }));
                    resolve({ myRaffles: myRaffles });
                });
        });
        promises.push(promiseRaffles);

        Promise.all(promises).then((resolve, reject) => {
            const allReleases = resolve.find((e) => e.allReleases != null)["allReleases"];
            const myRaffles = resolve.find((e) => e.myRaffles != null)["myRaffles"];

            // Combine data from releases & raffles
            const mapped = allReleases.map((rel) => ({
                raffle: myRaffles.find((raffle) => raffle.release_ref.id === rel.id),
                ...rel,
            }));
            // console.log(mapped);
            setReleases(mapped);
        });
    }, []);

    function showDeleteConfirm(documentId, releaseTitle) {
        confirm({
            title: "Are you sure you want to delete this release?",
            icon: <ExclamationCircleOutlined />,
            content: releaseTitle + " and all its raffles will be gone forever.",
            okText: "Delete",
            okType: "danger",
            cancelText: "Cancel",
            onOk() {
                message.error("Not supported yet.");
            },
        });
    }

    function editRaffle(record_id) {
        const release = releases.find((release) => release.id === record_id);
        const raffle = {
            raffle_id: release.raffle.id,
            sizes: release.raffle.sizes,
            entry_allowed_till: release.raffle.entry_allowed_till,
        };
        if (release.raffle.pick_up_location != undefined) {
            raffle["pick_up_location_id"] = release.raffle.pick_up_location.location_ref.id;
        }

        if (release.raffle.pick_up_location != undefined && release.raffle.shipping != undefined) {
            raffle["shippingOption"] = "combined";
        } else if (release.raffle.shipping != undefined) {
            raffle["shippingOption"] = "shipping";
        } else {
            raffle["shippingOption"] = "instore";
        }

        console.log(raffle);
        history.push({
            pathname: "/shop_releases/raffle",
            state: {
                record_id: record_id,
                edit_raffle: raffle,
                release_date: release.release_date,
                one_size: release.one_size,
            },
        });
    }

    function showWinners(record_id) {
        const release = releases.find((release) => release.id === record_id);
        history.push({
            pathname: "/shop_releases/winners",
            state: { raffle_id: release.raffle.id, release_title: release.title, release_date: release.release_date },
        });
    }

    function createRaffleLink(record, disabled) {
        return (
            <Tooltip title={disabled ? "Too late!" : ""}>
                <Button type="primary" disabled={disabled} onClick={() => createRaffle(record)}>
                    Create Raffle
                </Button>
            </Tooltip>
        );
    }

    function copyDirectLink(record) {
        let link;
        if (process.env.REACT_APP_DEPLOY_ENV === "prod") {
            link = "https://app.sneakerdraws.com/?link=https://sneakerdraws-client.firebaseapp.com";
        } else if (process.env.REACT_APP_DEPLOY_ENV === "staging") {
            link = "https://sneakerdrawsstaging.page.link/?link=https://sneakerdraws-staging-client.firebaseapp.com";
        } else {
            console.error("app.environment variable not set!");
            message.error("Error.");
            return;
        }

        link += "/r/" + record.id + "&isi=1517037892&ibi=com.buseedo.sd&st=Sneakerdraws+raffle";
        navigator.clipboard.writeText(link).then(
            function () {
                message.success("Direct link copied.");
                console.log("Link copied:", link);
            },
            function () {
                console.error("clipboard write failed");
            }
        );
    }

    function createRaffle(record) {
        history.push({
            pathname: "/shop_releases/raffle",
            state: {
                record_id: record.id,
                release_date: record.release_date,
                release_title: record.title,
                one_size: record.one_size,
            },
        });
    }

    const columns = [
        {
            width: 20,
            align: "center",
            render: (t, record) =>
                record.raffle != null && moment() < moment(record.release_date.toDate()).startOf("day") ? (
                    <Badge status="processing" title="Running raffle" />
                ) : (
                    <div />
                ),
        },
        {
            title: "Elite",
            width: 30,
            align: "center",
            render: (t, record) => (record.is_elite == true ? <Badge color="orange" title="Elite" /> : <div />),
        },
        {
            title: "Title",
            dataIndex: "title",
        },
        {
            title: "Price",
            width: 140,
            dataIndex: "price_formatted",
        },
        {
            title: "Release Date",
            dataIndex: "rel_date",
            width: 180,
            sorter: (a, b) => a.release_date - b.release_date,
        },
        {
            title: "Action",
            dataIndex: "action",
            width: 200,
            align: "right",
            render: (text, record) => (
                <Space size="middle">
                    {record.raffle != null ? (
                        <Space>
                            {record.raffle_did_run ? (
                                <>
                                    <Button onClick={() => showWinners(record.id)}>Winners</Button>
                                    <Button disabled>Archive</Button>
                                </>
                            ) : (
                                <>
                                    <Button disabled danger>
                                        Cancel
                                    </Button>
                                    <Button onClick={() => editRaffle(record.id)}>Edit</Button>
                                </>
                            )}
                        </Space>
                    ) : (
                        createRaffleLink(record, moment() > moment(record.release_date.toDate()))
                    )}
                    <Button onClick={() => copyDirectLink(record)}>Copy Link</Button>
                </Space>
            ),
        },
    ];

    return (
        <div>
            <Breadcrumb style={{ margin: "16px 0" }}>
                <Breadcrumb.Item>
                    <HomeOutlined />
                </Breadcrumb.Item>
                <Breadcrumb.Item>Releases</Breadcrumb.Item>
                <Breadcrumb.Item>Overview</Breadcrumb.Item>
            </Breadcrumb>

            <Divider />

            <Table dataSource={releases} columns={columns} />
        </div>
    );
}

export default ShopReleases;
