import React, { useState, useEffect, useContext } from 'react';
import {
    Divider,
    Breadcrumb,
    Spin,
    Row, Col,
    Form,
    Input,
    Upload,
    Button,
    Select,
    message
} from 'antd';
import {
    HomeOutlined,
    UploadOutlined
} from '@ant-design/icons';
import { db, storage } from "../../services/firebase";
import ShopContext from './ShopContext';
import { v4 as uuidv4 } from 'uuid';
import './ShopData.css';

var URI = require('urijs');

const { TextArea } = Input;
const { Option } = Select;

function ShopData() {

    const shop_id = useContext(ShopContext);
    const [saving, setSaving] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [bannerImages, setBannerImages] = useState([]);
    const [locations, setLocations] = useState([]);
    const [form] = Form.useForm();
    const [existingBanners, setExistingBanners] = useState([]);
    const images_base_ref = storage.ref().child('shops').child(shop_id);

    const normFile = e => {
        console.log('Upload event:', e);
        if (Array.isArray(e)) {
            return e;
        }
        if (e.fileList.length > 1) {
            e.fileList.shift();
        }
        return e && e.fileList;
    };

    useEffect(() => {
        const unsub1 = db.collection('shops').doc(shop_id).collection('locations').orderBy("street", "asc").onSnapshot(snapshot => {
            const allLocations = snapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            console.log(allLocations);
            setLocations(allLocations);

            const hq = allLocations.find(loc => loc.hq === true);
            if (hq != null) {
                form.setFieldsValue({ hq: hq.id });
            }
        });

        const unsub2 = db.collection('shops').doc(shop_id).onSnapshot(function (doc) {
            const shopData = doc.data();
            console.log(doc.data());

            form.setFieldsValue({
                name: shopData['title'],
                description: shopData['description'],
                homepage: shopData['homepage'],
                instagram: shopData['instagram'],
                open_hours: shopData['open_hours'],
                phone: shopData['phone'],
            });

            if(doc.data().banner_images != null) {
                setExistingBanners(doc.data().banner_images);
            }

            if (doc.data().banner_images && doc.data().banner_images.length > 0) {
                setBannerImages(doc.data().banner_images);
            } else {
                setBannerImages([]);
            }
        });
        return () => {
            console.log('cleanup');
            unsub1();
            unsub2();
        };

    }, []);

    const onFinish = values => {
        console.log('Form values:', values);
        setSaving(true);

        uploadImage(values.upload)
        .then(image_url => {

            let phone = values.phone.trim()
            if(phone.startsWith("+")) {
                phone = "00" + phone.slice(1);
            }

            const doc = {
                title: values.name,
                description: values.description,
                homepage: values.homepage,
                instagram: values.instagram,
                open_hours: values.open_hours,
                phone: phone
            }

            if(values.upload != null && values.upload.length > 0) {
                doc['banner_images'] = [image_url];
            }

            let location = locations.find(loc => loc.id === values.hq);
            let locationCopy = Object.assign({}, location);
            if(locationCopy != null) {
                delete locationCopy.id;
                delete locationCopy.hq;
                doc['hq'] = locationCopy;
            }

            // console.log('new doc:', doc);
            return db.collection('shops').doc(shop_id).update(doc);
        }).then(e => {
            var batch = db.batch();
            var i;
            for (i = 0; i < locations.length; i++) {
                var sfRef = db.collection('shops').doc(shop_id).collection('locations').doc(locations[i].id);
                batch.update(sfRef, { hq: locations[i].id === values.hq });
            }
            return batch.commit();
        }).then(e => {
            // Remove previous banner
            if (existingBanners != null && existingBanners.length > 0) {
                // todo: only filename!
                const decoded = decodeURIComponent(existingBanners[0]);
                const fileName = new URI(decoded).filename();
                console.log('removing fileName: ', fileName);

                const storageRef = storage.ref('shops').child(shop_id).child(fileName);
                console.log('Deleting old:', storageRef.fullPath);
                return storageRef.delete()
                    .then(r => {
                        return true;
                    }).catch(function (error) {
                        console.log('error deleting old image:', error);
                        return false;
                    });
            } else {
                return true;
            }
        }).then(e => {
            setSaving(false);            
            message.success({ content: 'Data saved' });
        }).catch(function (error) {
            setSaving(false);            
            message.error({ content: 'Data couldn\'t be saved' });
        });
    }

    function uploadImage(upload) {        
        return new Promise((resolve, reject) => {
            if(upload == null || upload.length == 0) {
                resolve('no image to upload');
                return;
            }
    
            const originalFileObj = upload[0].originFileObj;

            console.log('Uploading:', originalFileObj);
            const type = originalFileObj.type === 'image/jpeg' ? '.jpeg' : '.png';
            const storageRef = storage.ref('shops').child(shop_id).child(uuidv4() + type);
            console.log('storageRef:' + storageRef.fullPath);

            storageRef.put(originalFileObj)
                .then(function (snapshot) {
                    console.log('Uploaded a blob or file!');       
                    snapshot.ref.getDownloadURL().then(function(downloadURL) {
                        console.log('File available at', downloadURL);
                        resolve(downloadURL);
                      });             
                })
                .catch(function (error) {
                    console.error("Error uploading: ", error);
                    reject('error uploading');
                });
        });
    }

    const upload_props = {
        accept: "image/jpeg,image/png",
        onRemove: file => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
            console.log('after remove: ', fileList);
        },
        beforeUpload: file => {
            console.log('adding file: ', file);
            setFileList([file]);
            console.log('beforeUpload: ', fileList);
            return false;
        }
    };

    return (
        <div>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>
                    <HomeOutlined />
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    Shop settings
                </Breadcrumb.Item>
            </Breadcrumb>

            <Divider />

            <Spin spinning={saving} tip="Saving...">
                <Row>
                    <Col span={15}>
                        <Form form={form}
                            name="dynamic_form_item"
                            layout="horizontal"
                            labelCol={{ span: 6 }}
                            wrapperCol={{ span: 14 }}
                            onFinish={onFinish}
                        >
                            <Form.Item
                                label="Shop name"
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Enter the shop name',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Description"
                                name="description"
                            >
                                <TextArea rows={6} />
                            </Form.Item>

                            <Form.Item name="hq"
                                label="Headquarters"
                            >
                                <Select defaultValue="">
                                    {locations.map(item => (
                                        <Option value={item.id}>{item.street}, {item.city}</Option>
                                    ))}
                                </Select>
                            </Form.Item>

                            <Form.Item
                                label="Homepage"
                                name="homepage"
                                rules={[
                                    {
                                        type: 'url',
                                        message: 'Invalid url. Please use http:// or https:// prefix.',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Phone"
                                name="phone"
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Open hours"
                                name="open_hours"
                            >
                                <TextArea rows={3} />
                            </Form.Item>

                            <Form.Item
                                label="Instagram"
                                name="instagram"
                            >
                                <Input prefix="@" />
                            </Form.Item>

                            <Form.Item
                                name="upload"
                                label="Banner image"
                                valuePropName="fileList"
                                getValueFromEvent={normFile}
                            >
                                <Upload name="file" fileList={fileList} {...upload_props}>
                                    <Button>
                                        <UploadOutlined /> Select File
                                    </Button>
                                </Upload>
                            </Form.Item>
                            <Form.Item> </Form.Item>
                            <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
                                <Button htmlType="submit" type="primary" size="middle">
                                    Save
                            </Button>
                            </Form.Item>
                        </Form>
                    </Col>
                    <Col span={1}></Col>
                    <Col span={8}>{
                        bannerImages.map(image_url => {
                            return (<div className="shop_data_image"><img src={image_url} alt="Banner" /></div>)
                        })
                    }</Col>
                </Row>
            </Spin>
        </div>
    );
}

export default ShopData;