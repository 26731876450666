import React, { useState, useEffect } from 'react';
import {
    Table,
    Button,
    Divider,
    Breadcrumb,
    Badge,
    Space,
    Modal
} from 'antd';
import {
    HomeOutlined,
    ExclamationCircleOutlined
} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { db } from "../services/firebase";
import moment from 'moment';

const { confirm } = Modal;

function Posts(props) {
    let history = useHistory();

    const [posts, setPosts] = useState([]);

    useEffect(() => {
        const unsub = db.collection('feed_posts')
            .orderBy("publish_date", "desc")
            .onSnapshot(snapshot => {
                const allPosts = snapshot.docs.map(doc => ({
                    id: doc.id,
                    date_formatted: moment(doc.get('publish_date').toDate()).format('DD.MM.YYYY, HH:mm'),
                    ...doc.data()
                }));
                setPosts(allPosts);
            });
        return () => {
            console.log('cleanup');
            unsub();
        };
    }, []);

    function showPublishConfirm(post, should_publish) {
        confirm({
            title: should_publish ? 'Are you sure you want to publish this post?' : 'Are you sure you want to take offline this post?',
            icon: < ExclamationCircleOutlined / > ,
            content: post.title,
            okText: should_publish ? 'Publish' : 'Take offline',
            cancelText: 'Cancel',
            onOk() {
                db.collection("feed_posts").doc(post.id)
                .update({ published : should_publish })
                .then(function () {
                  console.log("Document successfully written!");
                })
                .catch(function (error) {
                  console.error("Error writing document: ", error);
                });
            },
        });
    }

    function editPost(post) {
        history.push({
            pathname: '/post',
            state: { post: post }
        })
    }

    function showDeleteConfirm(post) {
        // LATER
    }

    const columns = [
        {
            width: 20,
            align: 'center',
            render: (t, record) => (
                record.published == true
                    ? (<Badge status="success" title="Online" />)
                    : (<Badge status="default" title="Offline" />)
            )
        },
        {
            title: 'Title',
            dataIndex: 'title',
            sorter: (a, b) => { return a.title.localeCompare(b.title) },
        },
        {
            title: 'Publish date',
            dataIndex: 'date_formatted',
            width: 164,
            sorter: (a, b) => a.publish_date - b.publish_date,
            defaultSortOrder: 'descend'
        },
        {
            title: 'Action',
            dataIndex: 'action',
            width: 200,
            align: 'right',
            render: (text, record) => (
                <Space size="middle">

                    {
                        record.published == true ?
                            (<Button onClick={
                                () => showPublishConfirm(record, false)
                            } >Unpublish</Button>)
                            : (<Button type="primary" onClick={
                                () => showPublishConfirm(record, true)
                            } >Publish</Button>)
                    }

                    <Button onClick={
                        () => editPost(record)
                    } >Edit</Button>

                    <Button danger disabled onClick={
                        () => showDeleteConfirm(record)
                    } >Delete</Button>

                </Space>
            ),
        },
    ];

    return ( <div>
        <Breadcrumb style = {
            { margin: '16px 0' }
        } >
        <Breadcrumb.Item>
        <HomeOutlined />
        </Breadcrumb.Item>
        <Breadcrumb.Item>Posts</Breadcrumb.Item> <Breadcrumb.Item>Overview </Breadcrumb.Item> 
        </Breadcrumb>

        <Button type="primary" onClick={() =>
            history.push({
                pathname: '/post',
                state: { postId: db.collection("feed_posts").doc().id }
            })
        } >New Post</Button>

        <Divider />

        <Table dataSource={posts}
            columns={columns} />
        </div>
    );
}

export default Posts;