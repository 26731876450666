import React, { useState, useEffect } from "react";
import { Table, Divider, Breadcrumb, Switch, Button, message, Badge, Space } from "antd";
import { HomeOutlined, WarningTwoTone } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import { db } from "../services/firebase";
import moment from "moment";

const addressFormatter = require("@fragaria/address-formatter");
var HtmlToReactParser = require("html-to-react").Parser;

function Participants(props) {
    const location = useLocation();
    const [participants, setParticipants] = useState([]);
    const [allParticipants, setAllParticipants] = useState([]);
    const htmlToReactParser = new HtmlToReactParser();

    let release_id = null;
    let shop_id = null;
    let shop_title = null;
    let release_title = null;
    if (location.state != null) {
        release_id = location.state.release_id;
        shop_id = location.state.shop_id;
        shop_title = location.state.shop_title;
        release_title = location.state.release_title;
        // console.log(release_id, shop_id);
    }

    useEffect(() => {
        const releaseRef = db.collection("releases").doc(release_id);
        const shopRef = db.collection("shops").doc(shop_id);

        db.collectionGroup("user_raffles")
            .where("release_ref", "==", releaseRef)
            .where("entered_shop_refs", "array-contains", shopRef)
            .orderBy("entry_date")
            .get()
            .then(function (querySnapshot) {
                const dbParticipants = querySnapshot.docs.map(function (doc) {
                    let data = {
                        id: doc.id,
                        ref: doc.ref.parent,
                        e_date: moment(doc.get("entry_date").toDate()).format("DD.MM.YYYY, HH:mm"),
                        ...doc.data(),
                    };

                    if (doc.get("deviceData") != undefined && doc.get("deviceData.ip") != null) {
                        data["user_ip_address"] = doc.get("deviceData.ip");
                    }

                    console.log("data: ", data);

                    return data;
                });

                // Find duplicates
                let sorted_arr = dbParticipants.slice().sort((a, b) => {
                    if (a.user_ip_address != null && b.user_ip_address != null) {
                        return a.user_ip_address.localeCompare(b.user_ip_address);
                    }
                    return true;
                });
                let duplicate_ips = [];
                for (let i = 0; i < sorted_arr.length - 1; i++) {
                    if (
                        sorted_arr[i].user_ip_address != null &&
                        sorted_arr[i + 1].user_ip_address === sorted_arr[i].user_ip_address
                    ) {
                        duplicate_ips.push(sorted_arr[i].user_ip_address);
                    }
                }
                console.log("duplicates: ", duplicate_ips);

                // Mark duplicates in main array
                for (let i = 0; i <= dbParticipants.length - 1; i++) {
                    // console.log(i);
                    dbParticipants[i].is_duplicate =
                        duplicate_ips.findIndex((ip) => ip === dbParticipants[i].user_ip_address) > -1;
                }
                // console.log("dbParticipants: ", dbParticipants);

                setAllParticipants(dbParticipants);
                setParticipants(dbParticipants);
            });
    }, []);

    function onChangeShowDuplicates(checked) {
        if (checked == true) {
            let filtered = allParticipants.filter((p) => p.is_duplicate == true);
            setParticipants(filtered);
        } else {
            setParticipants(allParticipants);
        }
    }

    function onChangeShowElites(checked) {
        if (checked == true) {
            let filtered = allParticipants.filter((p) => p.is_elite == true);
            setParticipants(filtered);
        } else {
            setParticipants(allParticipants);
        }
    }

    function toggle_ban(participant, e) {
        const user_ref = participant.ref.parent;
        const source_button = e.currentTarget;
        user_ref
            .get()
            .then(function (doc) {
                if (doc.exists) {
                    const user = doc.data();
                    let is_banned = user["is_banned"];
                    if (is_banned == undefined) {
                        is_banned = false;
                    }
                    console.log("is_banned:", is_banned);
                    return ban(user_ref, source_button, !is_banned);
                }
            })
            .catch(function (error) {
                console.log("Error getting document:", error);
            });
    }

    function ban(user_ref, source_button, ban) {
        return user_ref
            .update({ is_banned: ban })
            .then(function () {
                source_button.innerText = ban ? "Unban" : "Ban";
            })
            .catch(function (error) {
                console.error("Error updating document: ", error);
                message.error("Failed to (un)ban this user. See error message in the console.");
            });
    }

    function deviceData(data) {
        let arr = [
            `IP: <a href='https://whatismyipaddress.com/ip/${data.ip}' target="_blank">${data.ip}</a>`,
            `Device: ${data.platform} ${data.version} (${data.locale})`,
            `Version: ${data.app_version}`,
        ];
        return arr.join("<br />");
    }

    function address(data) {
        let userAddress = addressFormatter.format(
            {
                // "houseNumber": 301,
                road: data.street || null,
                // "neighbourhood": "Crescent Park",
                city: data.city || null,
                postcode: data.zipCode || null,
                // "county": "Santa Clara County",
                state: data.state || null,
                // "country": "United States of America",
                countryCode: data.country || null,
            },
            {
                appendCountry: true,
            }
        );

        userAddress = userAddress.replace(/^\s+|\s+$/g, "").replace(/\n/g, "<br />"); // remove last \n and replace others with a br

        if (data.flat != undefined && data.flat != null) {
            userAddress = data.flat + "<br />" + userAddress;
        }

        return userAddress;
    }

    const columns = [
        {
            title: "Elite",
            width: 30,
            align: "center",
            render: (t, record) => (record.is_elite == true ? <Badge color="orange" title="Elite" /> : <div />),
        },
        {
            dataIndex: "is_duplicate",
            width: 50,
            render: (txt, record) => {
                if (record.is_duplicate == true) {
                    return <WarningTwoTone twoToneColor="#ff0000" />;
                }
            },
        },
        {
            title: "Name",
            dataIndex: "user_name",
            sorter: (a, b) => {
                return a.user_name.localeCompare(b.user_name);
            },
        },
        {
            title: "Address",
            dataIndex: "address",
            sorter: (a, b) => {
                let aCity = "";
                let bCity = "";
                if (a.address != undefined && a.address.city != null) {
                    aCity = a.address.city;
                } else if (a.city != undefined && a.city != null) {
                    aCity = a.city;
                }

                if (b.address != undefined && b.address.city != null) {
                    bCity = b.address.city;
                } else if (b.city != undefined && b.city != null) {
                    bCity = b.city;
                }

                return aCity.localeCompare(bCity);
            },
            render: (txt, rec) => {
                if (rec.address != undefined) {
                    let htmlInput = address(rec.address);
                    return htmlToReactParser.parse(htmlInput);
                } else {
                    return rec.city;
                }
            },
        },
        {
            title: "Email",
            dataIndex: "user_email",
            sorter: (a, b) => {
                return a.user_email.localeCompare(b.user_email);
            },
        },
        {
            title: "Entry date",
            dataIndex: "e_date",
            width: 160,
            sorter: (a, b) => a.entry_date - b.entry_date,
            defaultSortOrder: "ascend",
        },
        {
            title: "Size",
            dataIndex: "size",
            width: 70,
            sorter: (a, b) => a.size - b.size,
            render: (txt, rec) => {
                if (rec.size == 8000) {
                    return "-";
                } else {
                    return txt;
                }
            },
        },
        {
            title: "Device",
            dataIndex: "deviceData",
            width: 250,
            sorter: (a, b) => {
                // FIXME: mix of old & new
                if (
                    a.deviceData != undefined &&
                    b.deviceData != undefined &&
                    a.deviceData.ip != undefined &&
                    b.deviceData.ip != undefined &&
                    a.deviceData.ip != null &&
                    b.deviceData.ip != null
                ) {
                    return a.deviceData.ip.localeCompare(b.deviceData.ip);
                } else if (a.user_ip_address != null && b.user_ip_address != null) {
                    return a.user_ip_address.localeCompare(b.user_ip_address);
                }
                return true;
            },
            render: (txt, rec) => {
                if (rec.deviceData != undefined) {
                    let htmlInput = deviceData(rec.deviceData);
                    return htmlToReactParser.parse(htmlInput);
                }
                if (rec.user_ip_address != null) {
                    return rec.user_ip_address;
                } else {
                    return " ";
                }
            },
        },
        {
            title: "Action",
            dataIndex: "action",
            width: 120,
            align: "right",
            render: (text, record) => (
                <Button onClick={(e) => toggle_ban(record, e)}> {record.is_banned == true ? "Unban" : "Ban"} </Button>
            ),
        },
    ];

    return (
        <div>
            <Breadcrumb style={{ margin: "16px 0" }}>
                <Breadcrumb.Item>
                    <HomeOutlined />
                </Breadcrumb.Item>
                <Breadcrumb.Item>{shop_title}</Breadcrumb.Item> <Breadcrumb.Item>{release_title}</Breadcrumb.Item>{" "}
                <Breadcrumb.Item>Participants </Breadcrumb.Item>
            </Breadcrumb>

            <Divider />

            <div style={{ paddingBottom: 20 }}>
                <Space size="middle">
                    <Switch onChange={onChangeShowDuplicates} /> Show users from the same IP only
                    <Switch onChange={onChangeShowElites} /> Show elites only
                </Space>
            </div>

            <Table dataSource={participants} columns={columns} />
        </div>
    );
}

export default Participants;
