import React, { Component } from 'react';
import { Form, Input, Button, message, Spin } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { auth } from "../services/firebase";
import './Login.css';

class Login extends Component {

    constructor() {
        super();
        this.state = {
            isLoggingIn: false
        };
      }

    onFinish = values => {
        this.setState({isLoggingIn : true});
        auth().signInWithEmailAndPassword(values.email, values.password)
            .catch((error) => {
                this.setState({isLoggingIn : false});
                message.error(error.message);
            });
    };

    render() {
        return (
            <div id="login-page">
            <div id="login-box">
                <img src="/images/admin_200.png" alt="Sneakerdraws" className="logo" />
                <h1 className="login-header">Sneakerdraws Dashboard</h1>
                <Spin spinning={this.state.isLoggingIn} tip="Logging in...">
                    <Form
                        name="normal_login"
                        className="login-form"
                        initialValues={{ remember: true }}
                        onFinish={this.onFinish}
                    >
                        <Form.Item
                            name="email"
                            rules={[{ required: true, message: 'Enter your email' }]}
                        >
                            <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email" />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            rules={[{ required: true, message: 'Enter your password' }]}
                        >
                            <Input
                                prefix={<LockOutlined className="site-form-item-icon" />}
                                type="password"
                                placeholder="Password"
                            />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" className="login-form-button">
                                Log in
                        </Button>
                        </Form.Item>
                    </Form>
                </Spin>
                <div id="env-footer">                    
                env: {process.env.REACT_APP_DEPLOY_ENV}
                </div>
            </div>
            </div>
        );
    }
}


export default Login;