import React, { useState, useEffect, useContext } from 'react';
import {
    Divider,
    Breadcrumb,
    Spin,
    Row, Col,
    Form,
    Input,
    Button,
    Typography,
    message
} from 'antd';
import {
    HomeOutlined,
} from '@ant-design/icons';
import { auth } from '../../services/firebase';

const { Title } = Typography;

function ShopAccount() {

    const [form] = Form.useForm();

    const formItemLayout = {
        labelCol: {
            xs: {
                span: 12,
            },
            sm: {
                span: 4,
            },
        },
        wrapperCol: {
            xs: {
                span: 12,
            },
            sm: {
                span: 8,
            },
        },
    };

    const tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 12,
                offset: 0,
            },
            sm: {
                span: 8,
                offset: 4,
            },
        },
    };

    const onFinish = values => {
        console.log('Received values of form: ', values);

        var user = auth().currentUser;
        var credential = auth.EmailAuthProvider.credential(user.email, values.old_password);

        user.reauthenticateWithCredential(credential).then(function () {
            // User re-authenticated.
            user.updatePassword(values.password).then(function () {
                message.success('Password updated.');
                form.setFieldsValue({ password: '', confirm: '', old_password : '' });
            }).catch(function (error) {
                message.error('Can\'t change the passwod.');
            });
        }).catch(function (error) {
            message.error('Can\'t change the password. Wrong current password?');
        });
    };

    const passwordValidator = (rule, value) => {
        if (value.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/gm)) {
            return Promise.resolve();
        }
        return Promise.reject('Your password must include at least 8 characters including at least 1 uppercase letter, 1 lowercase letter, and 1 number.');
    }

    return (
        <div>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>
                    <HomeOutlined />
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    Account
                </Breadcrumb.Item>
                <Breadcrumb.Item>Overview</Breadcrumb.Item>
            </Breadcrumb>

            <Divider />

            <Title level={3}>Change password</Title>

            <p>Choose a unique password to protect your account</p>

            <Form
                {...formItemLayout}
                form={form}
                name="password_form"
                onFinish={onFinish}
            >
                <Form.Item
                    name="old_password"
                    label="Current password"
                    rules={[
                        {
                            required: true,
                            message: 'Enter current password',
                        }
                    ]}
                    hasFeedback
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item
                    name="password"
                    label="New password"
                    rules={[
                        {
                            required: true,
                            message: 'Enter new password',
                        },
                        { validator: passwordValidator }
                    ]}
                    hasFeedback
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item
                    name="confirm"
                    label="Retype new password"
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Retype new password',
                        },
                        ({ getFieldValue }) => ({
                            validator(rule, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }

                                return Promise.reject('The two passwords that you entered do not match.');
                            },
                        }),
                    ]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item {...tailFormItemLayout}>
                    <Button type="primary" htmlType="submit">
                        Update password
                    </Button>
                </Form.Item>

            </Form>
        </div>
    );
}

export default ShopAccount;