import React, { useEffect, useState } from "react";
import { Descriptions, Carousel, Collapse } from "antd";
import { db, storage } from "../../services/firebase";
import moment from "moment";
import "./ShopRaffleInfo.css";

const { Panel } = Collapse;

function ShopRaffleInfo(props) {
    const [release, setRelease] = useState({});
    const [images, setImages] = useState([]);

    const images_base_ref = storage.ref().child("releases").child(props.record_id);

    useEffect(() => {
        const unsub = db
            .collection("releases")
            .doc(props.record_id)
            .onSnapshot(function (doc) {
                setRelease(doc.data());

                if (doc.data().images && doc.data().images.length > 0) {
                    setImages(doc.data().images);
                } else {
                    setImages([]);
                }
            });

        return () => {
            console.log("cleanup");
            unsub();
        };
    }, []);

    return (
        <div>
            <Carousel autoplay>
                {images.map((image_url) => {
                    return (
                        <div>
                            <img src={image_url} />
                        </div>
                    );
                })}
            </Carousel>

            <Collapse>
                <Panel header="Release Info" key="1">
                    <Descriptions layout="vertical" bordered column={2}>
                        <Descriptions.Item label="Elite" span={2}>
                            {release.is_elite == true ? "Yes" : "No"}
                        </Descriptions.Item>
                        <Descriptions.Item label="Title" span={2}>
                            {release.title}
                        </Descriptions.Item>
                        <Descriptions.Item label="Description" span={2}>
                            {release.description}
                        </Descriptions.Item>
                        <Descriptions.Item label="Price">€ {release.price}</Descriptions.Item>
                        <Descriptions.Item label="Release date">
                            {release.release_date && moment(release.release_date.toDate()).format("DD.MM.YYYY, HH:mm")}
                        </Descriptions.Item>
                    </Descriptions>
                </Panel>
            </Collapse>
        </div>
    );
}

export default ShopRaffleInfo;
