import React, { useState, useEffect } from "react";
import { Table, Button, Divider, Breadcrumb, Modal } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { db } from "../services/firebase";

function Shops(props) {
    let history = useHistory();
    const [shops, setShops] = useState([]);

    useEffect(() => {
        const unsub = db
            .collection("shops")
            .orderBy("title")
            .onSnapshot((snapshot) => {
                const allShops = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setShops(allShops);
            });
        return () => {
            console.log("cleanup");
            unsub();
        };
    }, []);

    function showRaffle(shop) {
        // console.log(shop);
        history.push({
            pathname: "/raffles",
            state: { shop: shop },
        });
    }

    const columns = [
        {
            title: "Title",
            dataIndex: "title",
        },
        {
            title: "Action",
            dataIndex: "action",
            width: 200,
            align: "right",
            render: (text, record) => <Button onClick={() => showRaffle(record)}>Raffles</Button>,
        },
    ];

    return (
        <div>
            <Breadcrumb style={{ margin: "16px 0" }}>
                <Breadcrumb.Item>
                    <HomeOutlined />
                </Breadcrumb.Item>
                <Breadcrumb.Item>Shops </Breadcrumb.Item> <Breadcrumb.Item>Overview</Breadcrumb.Item>
            </Breadcrumb>

            <Divider />

            <Table dataSource={shops} columns={columns} />
        </div>
    );
}

export default Shops;
