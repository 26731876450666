import React, { useState, useEffect, useContext } from 'react';
import { 
    Table, 
    Button, 
    Divider, 
    Breadcrumb, 
    Space, 
} from 'antd';
import {
    HomeOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { db } from "../../services/firebase";
import ShopContext from './ShopContext';
const addressFormatter = require('@fragaria/address-formatter');

function Stores(props) {

    const shop_id = useContext(ShopContext);
    const [locations, setLocations] = useState([]);

    useEffect(() => {
        const unsub = db.collection('shops').doc(shop_id).collection('locations').orderBy("street", "asc").onSnapshot(snapshot => {
            const allLocations = snapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            console.log(allLocations);
            setLocations(allLocations);
        });
        return () => {
            console.log('cleanup');
            unsub();
        };

    }, []);

    function formatAddress(record) {
        const address = addressFormatter.format({
            // "houseNumber": 301,
            "road": record.street,
            // "neighbourhood": "Crescent Park",
            "city": record.city,
            "postcode": record.postal_code,
            // "county": "Santa Clara County",
            "state": record.state,
            // "country": "United States of America",
            "countryCode": record.country_code,
        }, {
            appendCountry: true
        });

        return address.replace(/^\s+|\s+$/g, '').replace(/\n/g, '<br />'); // remove last \n and replace others with a br;
    }

    const columns = [
        {
            title: 'Address',
            dataIndex: 'address',
            render: (text, record) => (
                <div dangerouslySetInnerHTML={{ __html: formatAddress(record) }} />
              ),
        },
        {
            title: 'Action',
            dataIndex: 'action',
            width: 200,
            align: 'right',
            render: (text, record) => (
                <Space size="middle">
                    <Button disabled danger>Delete</Button>
                    <Link
                        to={{
                            pathname: "/stores/store",
                            state: { store: record }
                        }}
                    >
                        <Button>Edit</Button>
                    </Link>
                </Space >
              ),
        },
    ];

    return (
        <div>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>
                    <HomeOutlined />
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    Stores
                </Breadcrumb.Item>
                <Breadcrumb.Item>Overview</Breadcrumb.Item>
            </Breadcrumb>

            <Link to="/stores/store">
                <Button type="primary">New Store</Button>
            </Link>
            <Divider />

            <Table dataSource={locations} columns={columns} />
        </div>
    );
}

export default Stores;