import React, { useState, useEffect } from 'react';
import {
    Table,
    Button,
    Divider,
    Breadcrumb,
    Modal,
} from 'antd';
import {
    HomeOutlined
} from '@ant-design/icons';
import { useHistory, useLocation } from 'react-router-dom';
import { db } from "../services/firebase";
import moment from 'moment';

function ShopRaffles(props) {

    let history = useHistory();
    const location = useLocation();
    const [raffles, setRaffles] = useState([]);

    let shop = null;
    if (location.state != null) {
        shop = location.state.shop;
        // console.log(shop);
        // form.setFieldsValue({ region: release.region });
    }

    useEffect(() => {
        const unsub = db.collection('shops').doc(shop.id).collection('raffles').orderBy("release_date", "desc").onSnapshot(snapshot => {
            const allRaffles = snapshot.docs.map(doc => ({
                id: doc.id,
                rel_date: moment(doc.get('release_date').toDate()).format('DD.MM.YYYY, HH:mm'),
                ...doc.data()
            }));
            setRaffles(allRaffles);
        });
        return () => {
            console.log('cleanup');
            unsub();
        };
    }, []);

    function showParticipants(raffle) {
        // console.log('showParticipants for raffle:', raffle);
        history.push({
            pathname: '/participants',
            state: { 
                release_id: raffle.release_ref.id, 
                shop_id: shop.id,
                shop_title: shop.title,
                release_title: raffle.release_title
            }
        });
    }

    const columns = [{
        title: 'Title',
        dataIndex: 'release_title',
        sorter: (a, b) => { return a.release_title.localeCompare(b.release_title)},
    },
    {
        title: 'Release Date',
        dataIndex: 'rel_date',
        width: 180,
        sorter: (a, b) => a.release_date - b.release_date,
        defaultSortOrder: 'descend'
    },
    {
        title: 'Action',
        dataIndex: 'action',
        width: 200,
        align: 'right',
        render: (text, record) => ( 
            <Button onClick={
                () => showParticipants(record)
            } >Participants</Button>
        ),
    },
];

    return ( <div>
        <Breadcrumb style = {
            { margin: '16px 0' }
        } >
        <Breadcrumb.Item>
        <HomeOutlined />
        </Breadcrumb.Item>
        <Breadcrumb.Item>{ shop.title }</Breadcrumb.Item> <Breadcrumb.Item>Raffles </Breadcrumb.Item> 
        </Breadcrumb>

        <Divider />

        <Table dataSource = { raffles }
        columns = { columns } />
        </div>
    );
}

export default ShopRaffles;