import firebase from 'firebase';
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

const firebaseConfigStaging = {
    apiKey: "AIzaSyAtVEySl0wOdk9d3A2iiyfz3VeH8JbgMno",
    authDomain: "sneakerdraws-staging.firebaseapp.com",
    databaseURL: "https://sneakerdraws-staging.firebaseio.com",
    projectId: "sneakerdraws-staging",
    storageBucket: "sneakerdraws-staging.appspot.com",
    messagingSenderId: "925946545279",
    appId: "1:925946545279:web:3ad7668bc5631f7e03ab4a"
};

const firebaseConfigProd = {
    apiKey: "AIzaSyDZCO83Q0QyFZczzfJVRBnvn2hhfqWozG0",
    authDomain: "sneakerdraws-65e4a.firebaseapp.com",
    databaseURL: "https://sneakerdraws-65e4a.firebaseio.com",
    projectId: "sneakerdraws-65e4a",
    storageBucket: "sneakerdraws-65e4a.appspot.com",
    messagingSenderId: "44848260345",
    appId: "1:44848260345:web:e1fcb2649997a402afae28",
    measurementId: "G-3GCRNE69K2"
};

if (process.env.REACT_APP_DEPLOY_ENV === 'prod') {
    firebase.initializeApp(firebaseConfigProd);
} else if (process.env.REACT_APP_DEPLOY_ENV === 'staging') {
    firebase.initializeApp(firebaseConfigStaging);
} else {
    console.error('app.environment variable not set!');
}

export const auth = firebase.auth;
export const db = firebase.firestore();
export const dbFieldValue = firebase.firestore.FieldValue;
export const storage = firebase.storage();
export const functions = firebase.app().functions('europe-west3');