import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Switch } from 'react-router-dom';
import { auth } from "../services/firebase";
import Releases from './Releases';
import Users from './Users';
import Shops from './Shops';
import ShopRaffles from './ShopRaffles';
import Participants from './Participants';
import Posts from './Posts';
import PostEditor from './PostEditor';

// antd
import { Layout, Menu } from 'antd';
import {
  LogoutOutlined,
  AppstoreOutlined,
  UserOutlined,
  UsergroupAddOutlined,
  ShopOutlined,
  NotificationOutlined
} from '@ant-design/icons';
import ReleaseEditor from './ReleaseEditor';

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

class Admin extends Component {
  constructor() {
    super();
    this.state = {
      collapsed: false,
    };
  }

  onCollapse = collapsed => {
    console.log(collapsed);
    this.setState({ collapsed });
  };

  handleRightMenuClick = e => {
    if (e.key === 'signout') {
      auth().signOut();
    }
  };

  render() {
    return (
      <Router>
        <Layout style={{ minHeight: '100vh' }}>
          <Sider collapsible collapsed={this.state.collapsed} onCollapse={this.onCollapse}>
            <img src="/images/admin_200.png" alt="Sneakerdraws" className="logo" />
            <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline">            
              <Menu.Item icon={<AppstoreOutlined />} key="1">
                <Link to="/releases">Releases</Link>
              </Menu.Item>
              <Menu.Item icon={<NotificationOutlined />}>
                <Link to="/posts">Posts</Link>
              </Menu.Item>
              <Menu.Item icon={<ShopOutlined />}>
                <Link to="/shops">Shops</Link>
              </Menu.Item>
              <Menu.Item icon={<UsergroupAddOutlined />}>
                <Link to="/users">Users</Link>
              </Menu.Item>
            </Menu>
          </Sider>
          <Layout className="site-layout">
            <Header className="site-layout-background" style={{ padding: 0, textAlign: 'right' }}>
              <Menu mode="horizontal" onClick={this.handleRightMenuClick} style={{backgroundColor: '#f0f2f5'}}>
                <SubMenu title={auth().currentUser.displayName} icon={<UserOutlined />}>
                  <Menu.Item key="signout" icon={<LogoutOutlined />}>
                    Sign out
                  </Menu.Item>
                </SubMenu>
              </Menu>
            </Header>
            <Content style={{ margin: '0 16px' }}>
              <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
              <Switch>
                  <Route path="/posts">
                    <Posts />
                  </Route>
                  <Route path="/post">
                    <PostEditor />
                  </Route>
                  <Route path="/releases">
                    <Releases />
                  </Route>
                  <Route path="/release">
                    <ReleaseEditor />
                  </Route>
                  <Route path="/users">
                    <Users />
                  </Route>
                  <Route path="/shops">
                    <Shops />
                  </Route>
                  <Route path="/raffles">
                    <ShopRaffles />
                  </Route>
                  <Route path="/participants">
                    <Participants />
                  </Route>
                </Switch>
              </div>
            </Content>
            <Footer style={{ textAlign: 'center' }}>Sneakerdraws ©2020, v.{process.env.REACT_APP_VERSION} ({process.env.REACT_APP_DEPLOY_ENV})</Footer>
          </Layout>
        </Layout>
      </Router>
    )
  }
}
export default Admin;
