import React, { useState, useEffect } from "react";
import { Descriptions, Button, Divider, message, Badge, Form, Input, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { functions, db } from "../services/firebase";
import moment from "moment";
const { confirm } = Modal;
const layout = {
    labelCol: { span: 2 },
    wrapperCol: { span: 4 },
};
const tailLayout = {
    wrapperCol: { offset: 2, span: 2 },
};

const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
};

function Users(props) {
    const [user, setUser] = useState(null);
    const [role, setRole] = useState("-");
    const [elites, setElites] = useState(0);

    useEffect(() => {
        db.collection("users")
            .where("is_elite", "==", true)
            .get()
            .then(function (querySnapshot) {
                setElites(querySnapshot.size);
                // console.log("querySnapshot.length:", querySnapshot.size);
            });
    }, []);

    // // TEMP
    // useEffect(() => {
    //     let releaseRef = db.collection("releases").doc("LpjtFoMc7aj7M7XmZrUZ");
    //     var ref = db.collectionGroup("user_raffles").where("release_ref", "==", releaseRef);
    //     // .where("use_shipping", "==", true);

    //     const unsub = ref.get().then(function (querySnapshot) {
    //         console.log("res:");
    //         querySnapshot.forEach((doc) => {
    //             console.log(doc.get("release_title"));
    //             // doc.ref.delete();
    //         });
    //     });
    //     // console.log("DONE DELETING");
    //     return () => {
    //         console.log("cleanup");
    //         unsub();
    //     };
    // }, []);
    // // TEMP END

    const onFinish = (values) => {
        console.log("Success:", values);

        const getUserByEmail = functions.httpsCallable("getUserByEmail");
        getUserByEmail({ email: values.email })
            .then(function (result) {
                if (result.data == null) {
                    message.error({ content: "User not found." });
                } else {
                    setUser(result.data);
                    setRole("-");
                    console.log(result.data);

                    if (result.data["customClaims"] != null) {
                        if (result.data["customClaims"]["admin"] != null) {
                            setRole("Admin");
                        } else if (result.data["customClaims"]["shop_admin"] != null) {
                            setRole(`Admin for shop ${result.data["customClaims"]["shop_admin"]}`);
                        }
                    }
                }
            })
            .catch(function (error) {
                console.log(error);
                message.error({ content: "User coulnt't be loaded." });
            });
    };

    const onFinishMakeShopAdmin = (values) => {
        const makeShopAdmin = functions.httpsCallable("makeShopAdmin");
        makeShopAdmin({ uid: user["uid"], shop_id: values.shop_id }).then(function (result) {
            console.log("Set. res:", values);
            // if (result.data == null) {
            //     message.error({ content: 'User not found.' });
            // } else {
            //     setUser(result.data);
            // }
        });
    };

    function disableUser() {
        console.log("user['uid']:", user["uid"]);
        const disableUserCall = functions.httpsCallable("disableUser");
        disableUserCall({ uid: user["uid"] })
            .then(function (result) {
                console.log("Set. res:", result);
                message.success("User is now disabled.");
            })
            .catch(function (error) {
                console.log(error);
                message.error({ content: "User coulnt't be disabled." });
            });
    }

    function showDisableConfirm() {
        confirm({
            title: "Are you sure you want to disable this user?",
            icon: <ExclamationCircleOutlined />,
            content: user.displayName + " will not be able to login next time.",
            okText: "Disable",
            okType: "danger",
            cancelText: "Cancel",
            onOk() {
                disableUser();
            },
        });
    }

    return (
        <div>
            <Descriptions
                title="Elite"
                layout="horizontal"
                bordered
                column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
            >
                <Descriptions.Item label="Elite users">{elites}</Descriptions.Item>
            </Descriptions>
            <Divider />
            <Form
                {...layout}
                name="basic"
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >
                <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                        {
                            required: true,
                            message: "Enter user's identifier",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item {...tailLayout}>
                    <Button type="primary" htmlType="submit">
                        Get user info
                    </Button>
                </Form.Item>
            </Form>

            {user != null && (
                <div>
                    <Descriptions
                        title="User Info"
                        layout="vertical"
                        bordered
                        column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
                        extra={
                            <Button type="primary" danger onClick={() => showDisableConfirm()}>
                                Disable
                            </Button>
                        }
                    >
                        <Descriptions.Item label="Elite">
                            <Badge
                                color={user["db_data"]["is_elite"] == true ? "green" : "#ddd"}
                                text={user["db_data"]["is_elite"] == true ? "Yes" : "No"}
                            />
                        </Descriptions.Item>
                        <Descriptions.Item label="UID">{user["uid"]}</Descriptions.Item>
                        <Descriptions.Item label="Enabled">{user["disabled"] == true ? "No" : "Yes"}</Descriptions.Item>
                        <Descriptions.Item label="Name">{user["displayName"]}</Descriptions.Item>
                        <Descriptions.Item label="Email">
                            <Badge
                                color={user["emailVerified"] === true ? "green" : "red"}
                                text={user["emailVerified"] === true ? "Verified" : "Not verified"}
                            />
                        </Descriptions.Item>
                        <Descriptions.Item label="Admin">{role}</Descriptions.Item>
                        <Descriptions.Item label="Created">
                            {moment(user["metadata"]["creationTime"]).format("DD.MM.YYYY HH:mm")}
                        </Descriptions.Item>
                        <Descriptions.Item label="Last sign-in">
                            {moment(user["metadata"]["lastSignInTime"]).format("DD.MM.YYYY HH:mm")}
                        </Descriptions.Item>
                        <Descriptions.Item label="Banned">
                            {user["db_data"]["is_banned"] == true ? "Yes" : "No"}
                        </Descriptions.Item>
                        <Descriptions.Item label="Phone">{user["db_data"]["phone"]}</Descriptions.Item>
                    </Descriptions>

                    <Divider />

                    <Form
                        {...layout}
                        name="basic"
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={onFinishMakeShopAdmin}
                        onFinishFailed={onFinishFailed}
                    >
                        <Form.Item
                            label="Shop ID"
                            name="shop_id"
                            rules={[
                                {
                                    required: true,
                                    message: "Enter shop's identifier",
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item {...tailLayout}>
                            <Button type="primary" htmlType="submit">
                                Make admin
                            </Button>
                        </Form.Item>
                    </Form>

                    <Divider />
                </div>
            )}
        </div>
    );
}

export default Users;
