import React, { useState, useEffect } from 'react';
import { 
    Button, 
    Table,
    Space,
    Modal,
    Upload
} from 'antd';
import {
    UpOutlined, DownOutlined, ExclamationCircleOutlined, UploadOutlined
} from '@ant-design/icons';
import { db, storage, dbFieldValue } from "../services/firebase";
import { v4 as uuidv4 } from 'uuid';
var URI = require('urijs');

const { confirm } = Modal;

function ReleaseEditorImages(props) {

    var Direction = {
        Up : 0, Down : 1
    }

    const [images, setImages] = useState([]);
    const images_base_ref = storage.ref().child('releases').child(props.release_id);

    useEffect(() => {
        const unsub = db.collection('releases').doc(props.release_id).onSnapshot(function (doc) {
            if (doc.data().images && doc.data().images.length > 0) {
                setImages(doc.data().images);
            } else {
                setImages([]);
            }
        });

        return () => {
            console.log('cleanup');
            unsub();
        };
    }, []);

    function showDeleteConfirm(index) {
        confirm({
            title: 'Delete Image',
            icon: <ExclamationCircleOutlined />,
            content: 'Are you sure you want to delete this image?',
            okText: 'Delete',
            okType: 'danger',
            cancelText: 'Cancel',
            onOk() {
                deleteImage(index);
            },
        });
    }

    function deleteImage(index) {
        var element = images[index];
        const decoded = decodeURIComponent(element);
        const fileName = new URI(decoded).filename();
        console.log('removing: ', fileName);

        db.collection('releases').doc(props.release_id).update({
            images: dbFieldValue.arrayRemove(element)
        });

        images_base_ref.child(fileName).delete()
    }

    function move(index, direction) {
        const image_names = images.map(i => i); // just copy arrray
        console.log('image_names: ', image_names);

        const toIndex = direction === Direction.Down ? index + 1 : index - 1;
        // LATER: check array bounds (not really needed, b.c. we're doing this in UI)

        var element = image_names[index];
        image_names.splice(index, 1);
        image_names.splice(toIndex, 0, element);

        console.log('new image_names: ', image_names);

        db.collection('releases').doc(props.release_id).update({
            images: image_names
        });
    }

    const upload_props = {
        accept: "image/jpeg,image/png",
        beforeUpload: file => {
            console.log('adding file: ', file);

            uploadImage(file)
                .then(downloadURL => {
                    console.log('File available at', downloadURL);

                    db.collection('releases').doc(props.release_id).update({
                        images: dbFieldValue.arrayUnion(downloadURL)
                    });
                });
            
            return false;
        }
    };

    function uploadImage(originalFileObj) {        
        return new Promise((resolve, reject) => {
            
            console.log('Uploading:', originalFileObj);
            const type = originalFileObj.type === 'image/jpeg' ? '.jpeg' : '.png';
            const storageRef = images_base_ref.child(uuidv4() + type);
            console.log('storageRef:' + storageRef.fullPath);

            storageRef.put(originalFileObj)
                .then(function (snapshot) {
                    console.log('Uploaded a blob or file!');                    
                    storageRef.getDownloadURL().then(function(downloadURL) {
                        resolve(downloadURL);
                      });
                })
                .catch(function (error) {
                    console.error("Error uploading: ", error);
                    reject('error uploading');
                });
        });
    }

    const columns = [
        {
            title: 'Image',
            dataIndex: 'image',
            render: (text, record) => (
                <img src={record} alt="" style={{height: 350}} />
            )
        },
        {
            title: 'Action',
            dataIndex: 'action',
            width: 200,
            align: 'right',
            render: (text, record, index) => (
                <Space size="small">
                    <Button onClick={() => move(index, Direction.Up)} disabled={ index === 0 }>
                        <UpOutlined />
                    </Button>
                    <Button onClick={() => move(index, Direction.Down)} disabled={ index === images.length - 1 }>
                        <DownOutlined />
                    </Button>
                    <Button danger onClick={() => showDeleteConfirm(index)}>
                        Delete
                    </Button>
                </Space >
              ),
        },
    ];

    return (
        <>
            <Upload listType="text" {...upload_props}>
                <Button>
                    <UploadOutlined /> Upload image...</Button>
            </Upload>
            <br />
            <Table dataSource={images} columns={columns} />
        </>
    );
}

export default ReleaseEditorImages;