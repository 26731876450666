import React, { useState, useEffect, useContext } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Divider, Breadcrumb, Spin, Row, Col, Form, Input, Button, Select, message } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import { db } from "../../services/firebase";
import ShopContext from "./ShopContext";
import "./ShopData.css";

const { Option } = Select;

const countries = [
    { name: "Germany", code: "DE" },
    { name: "France", code: "FR" },
    { name: "Netherlands", code: "NL" },
    { name: "Belgium", code: "BE" },
    { name: "Spain", code: "ES" },
    { name: "Luxembourg", code: "LU" },
    { name: "Sweden", code: "SE" },
];

function StoreEditor() {
    const history = useHistory();
    const [form] = Form.useForm();
    const [saving, setSaving] = useState(false);
    const location = useLocation();
    const shop_id = useContext(ShopContext);

    let store = null;
    if (location.state != null) {
        store = location.state.store;

        form.setFieldsValue({ country: store.country_code });
    }

    const onFinish = (values) => {
        console.log("Form values:", values);
        setSaving(true);

        db.collection("shops")
            .doc(shop_id)
            .get()
            .then(function (shopDoc) {
                const shopData = shopDoc.data();

                const doc = {
                    shop_title: shopData["title"],
                    street: values.street,
                    postal_code: values.postal_code,
                    city: values.city,
                    state: values.state,
                    country_code: values.country,
                };

                if (location.state != null) {
                    db.collection("shops")
                        .doc(shop_id)
                        .collection("locations")
                        .doc(location.state.store.id)
                        .update(doc)
                        .then(updateHQ(doc))
                        .then(updateShopRaffles(doc))
                        .then(openStores())
                        .catch(function (error) {
                            console.log("Error :", error);
                            setSaving(false);
                            message.error("Failed to save.");
                        });
                } else {
                    db.collection("shops")
                        .doc(shop_id)
                        .collection("locations")
                        .doc()
                        .set(doc)
                        .then(openStores())
                        .catch(function (error) {
                            console.log("Error :", error);
                            setSaving(false);
                            message.error("Failed to save.");
                        });
                }
            });
    };

    // update shop_raffles > location
    function updateShopRaffles(doc) {
        return new Promise((resolve, reject) => {
            const locationRef = db
                .collection("shops")
                .doc(shop_id)
                .collection("locations")
                .doc(location.state.store.id);
            db.collection("shops")
                .doc(shop_id)
                .collection("raffles")
                .where("pick_up_location.location_ref", "==", locationRef)
                .get()
                .then(function (querySnapshot) {
                    const raffles = querySnapshot.docs.map((raffleDoc) => raffleDoc.ref);

                    // console.log('raffles:', raffles);

                    // Otherwise, it will be deleted (nested objects are overriden on write)
                    doc["location_ref"] = locationRef;

                    var batch = db.batch();
                    var i;
                    for (i = 0; i < raffles.length; i++) {
                        batch.update(raffles[i], { pick_up_location: doc });
                    }
                    return batch.commit();
                });
        });
    }

    function updateHQ(doc) {
        return new Promise((resolve, reject) => {
            if (location.state.store.hq != true) {
                resolve(true);
                console.log("Not an actual HQ, no need to update.");
                return;
            }

            return db.collection("shops").doc(shop_id).update({ hq: doc });
        });
    }

    function openStores() {
        history.push({
            pathname: "/stores",
        });
    }

    return (
        <div>
            <Breadcrumb style={{ margin: "16px 0" }}>
                <Breadcrumb.Item>
                    <HomeOutlined />
                </Breadcrumb.Item>
                <Breadcrumb.Item>Stores</Breadcrumb.Item>
                <Breadcrumb.Item>{store != null ? "Edit" : "Create"}</Breadcrumb.Item>
            </Breadcrumb>

            <Divider />

            <Spin spinning={saving} tip="Saving...">
                <Row>
                    <Col span={15}>
                        <Form
                            form={form}
                            name="dynamic_form_item"
                            layout="horizontal"
                            initialValues={{
                                street: store && store.street,
                                postal_code: store && store.postal_code,
                                city: store && store.city,
                                state: store && store.state,
                            }}
                            labelCol={{ span: 6 }}
                            wrapperCol={{ span: 14 }}
                            onFinish={onFinish}
                        >
                            <Form.Item
                                label="Street"
                                name="street"
                                rules={[
                                    {
                                        required: true,
                                        message: "Required",
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Postal code"
                                name="postal_code"
                                rules={[
                                    {
                                        required: true,
                                        message: "Required",
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="City"
                                name="city"
                                rules={[
                                    {
                                        required: true,
                                        message: "Required",
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="State"
                                name="state"
                                rules={[
                                    {
                                        required: true,
                                        message: "Required",
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item name="country" label="Country" rules={[{ required: true, message: "Required" }]}>
                                <Select defaultValue="">
                                    {countries.map((country) => (
                                        <Option value={country.code}>{country.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item> </Form.Item>
                            <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
                                <Button htmlType="submit" type="primary" size="middle">
                                    Save
                                </Button>
                            </Form.Item>
                        </Form>
                    </Col>
                    <Col span={9}></Col>
                </Row>
            </Spin>
        </div>
    );
}

export default StoreEditor;
